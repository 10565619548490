import service from "utils/axios"
import {createAsyncThunk} from "@reduxjs/toolkit"


export const regUser = createAsyncThunk(
	'signup',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('signup', data)
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const loginUser = createAsyncThunk(
	'signin',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('signin', data)
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)