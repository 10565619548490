import {configureStore} from "@reduxjs/toolkit"
import authReducer from './slice/auth'
import eventsReducer from './slice/events'
import statesReducer from './slice/states'
import notifyReducer from './slice/notify'
import settingsReducer from './slice/settings'
import {combineReducers} from 'redux'
import {
	persistReducer,
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {CookieStorage} from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
import service from 'utils/axios'

// TODO: move auth data to coockie storage
const authPersistConfig = {
	key: 'auth',
	storage: new CookieStorage(Cookies),
	blacklist: ['pending']
}

// TODO: check why persist works on state fields not within safelist
const eventsPersistConfig = {
	key: 'events',
	storage,
	blacklist: ['intent_list', 'event_list', 'curr_event_context', 'setting_event_data', 'setting_marker']
}

const statesPersistConfig = {
	key: 'states',
	storage,
	blacklist: ['state', 'state_context']
}

const notifyPersistConfig = {
	key: 'notify',
	storage,
	blacklist: ['notification', 'severity']
}

const settingsPersistConfig = {
	key: 'settings',
	storage,
	blacklist: []
}

const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
	events: persistReducer(eventsPersistConfig, eventsReducer),
	states: persistReducer(statesPersistConfig, statesReducer),
	notify: persistReducer(notifyPersistConfig, notifyReducer),
	settings: persistReducer(settingsPersistConfig, settingsReducer)
})


const rootPersistConfig = {
	key: 'root',
	storage,
	blacklist: ['auth', 'events', 'states', 'notify']
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)


export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, REGISTER]
			}
		})
})

service.interceptors.request.use(function (config) {
	let auth_d = store.getState().auth
	if (auth_d && auth_d.user && auth_d.user.token) {
		config.headers.Authorization = auth_d.user.token
	}
	return config
})

export const persistedStore = persistStore(store)