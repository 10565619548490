import classes from './SliderMenu.module.css'
import {useState} from 'react'
import {styled} from '@mui/material/styles'
import {grey} from '@mui/material/colors'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'

const Puller = styled('div')(({theme}) => ({
	width: 100,
	height: 6,
	backgroundColor: grey[500],
	borderRadius: 3,
	position: 'absolute',
	top: 8,
	left: 'calc(50% - 50px)'
}))

const ContentBox = (props) => {
	const {children} = props

	return (
		<Box sx={{
			pt: 2,
			px: 2,
			pb: 2,
			height: '100%',
			overflow: 'auto'
		}}>
			{children}
		</Box>
	)
}

export default function SliderMenu(props) {
	const {swipeWidth, children, open, onClose, onOpen, white} = props

	return (
		<SwipeableDrawer
			open={open}
			onClose={onClose}
			onOpen={onOpen}
			anchor="bottom"
			disableSwipeToOpen={true}
			swipeAreaWidth={swipeWidth ? swipeWidth : 100}
			PaperProps={{
				sx: {
					width: '100%',
					maxWidth: '430px',
					borderTopLeftRadius: 13,
					borderTopRightRadius: 13,
					// bgcolor: '#f2f1e1',
					bgcolor: white ? 'white' : '#f7f7f7'
				}
			}}
			BackdropProps={{invisible:true}}
		>
			<ContentBox>
				<Puller />
				{children}
			</ContentBox>
		</SwipeableDrawer>
	)
}