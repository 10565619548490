import classes from './RestorePasswordScreen.module.css'
import service from "utils/axios"
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {notifyAction} from 'store/slice/notify'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'


export default function RestorePasswordScreen({stateHandler}) {
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const handleSubmit = async (e) => {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		await service.post("/recover", {
			'email': data.get('email')
		})
		.then((res) => {
			// TODO: success toast
			stateHandler('Login')
		})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	return (
		<Container component="main" maxWidth="xs" sx={{mb: 10}}>
			<CssBaseline />

			<Box
				sx={{
					marginTop: 1,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>

				<Typography component="h1" variant="h5" sx={{marginBottom: 2}}>
						{t('rec_pass_title')}
				</Typography>

				<Divider flexItem />

				<Typography variant="body1" sx={{marginTop: 2}}>
						{t('rec_pass_desc')}
				</Typography>

				<Box component="form" onSubmit={handleSubmit} sx={{mt:1}}>
					<TextField
						margin="normal"
						fullWidth
						id="email"
						label={t('email_address')}
						name="email"
						autoComplete="email"
						autoFocus
						variant="filled"
					/>

					<Button
						type="submit"
						variant="contained"
						sx={{mt:3,mb:2,width: 200,left:'calc(50% - 100px)'}}
					>
						{t('rec_pass_button')}
					</Button>

					<Grid container justifyContent="flex-end">
						<Grid item>
							<Link href="#" variant="body2" onClick={()=>{stateHandler('Login')}}>
								{t('remember_pass')}
							</Link>
						</Grid>
					</Grid>
				</Box>

			</Box>
		</Container>
	)
}