import dayjs from 'dayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import {styled} from '@mui/material/styles'
import service from "utils/axios"
import {useTranslation} from 'react-i18next'
import {notifyAction} from 'store/slice/notify'

// import EventTags from './EventTags/EventTags'
import classes from './EventContext.module.css'

import {useState, useEffect} from 'react'
import {
	settingEventDataAction,
	removeCurrentEventContextAction,
	setMyIntentOnEventContextAction,
	removeMyIntentOnEventContextAction
} from 'store/slice/events'
import {fetchMyIntents} from 'store/thunks/events'
import {fetchFriendList} from 'store/thunks/user'
import {setEventEditStateAction} from 'store/slice/states'
import {reloadEventListAction} from 'store/slice/events'
import {useDispatch, useSelector} from 'react-redux'
import {ackEvent, publishEvent, removeIntent, createIntent, fetchEventByID, cancelEvent} from "store/thunks/events"
import ParticipantList from 'components/ParticipantList/ParticipantList'
import FriendList from 'components/FriendList/FriendList'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import Modal from '@mui/material/Modal'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import WrongLocationRoundedIcon from '@mui/icons-material/WrongLocationRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import ReportRoundedIcon from '@mui/icons-material/ReportRounded'
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded'
import EditLocationAltRoundedIcon from '@mui/icons-material/EditLocationAltRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded'
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'



function ReportModal({eventContext, onSubmit, onClose}) {
	const [rep, setRep] = useState('')
	const {t} = useTranslation()

	const handleSubmit = async (e) => {
		e.preventDefault()
		await onSubmit(eventContext.id, rep)
		onClose()
	}

	return (
		<Modal
			open={!!eventContext}
			onClose={onClose}
		>
			<Box
				sx={{
					maxWidth: 400,
					bgcolor: '#fafafa',
					position: 'absolute',
					top: '30%',
					left: '10%',
					width: '80%',
					borderRadius: '6px',
					boxShadow: 24,
					p: 1
				}}
				component="form"
				onSubmit={handleSubmit}
			>
				{eventContext &&
					<Grid container spacing={1}>

						<Grid item xs={12}>
							<Typography variant="h6">{t('rep_violation')}:</Typography>
						</Grid>

						<Grid item xs={12}>
							<TextField
								fullWidth
								multiline
								rows={2}
								value={rep}
								inputProps={{
									maxLength: 200
								}}
								helperText={`${rep ? rep.length : 0}/${200}`}
								onChange={(e)=>{setRep(e.target.value)}}
							/>
						</Grid>

						<Grid item xs={6}>
							<Button fullWidth type="submit" key="send-report" size="medium" startIcon={<SendRoundedIcon />} variant="contained" color="info">
								{t('send')}
							</Button>
						</Grid>
						<Grid item xs={6} sx={{mb:1}}>
							<Button fullWidth type="reset" key="cancel-report" size="medium" variant="outlined" color="error" onClick={onClose}>
								{t('cancel')}
							</Button>
						</Grid>

					</Grid>
				}

			</Box>
		</Modal>
	)
}


export default function EventContext({showLocation, eventIntent, eventData, onShowEventLocation, onClose}) {
	const [reload, setReload] = useState(false)
	const [openParticipantList, setOpenParticipantList] = useState(false)
	const [profileContext, setProfileContext] = useState(null)
	const {t} = useTranslation()

	const [friendListOpen, setFriendListOpen] = useState(false)
	const handleOpenFriendList = () => setFriendListOpen(true)
	const handleCloseFriendList = () => setFriendListOpen(false)
	const userContextHandler = (e) => {
		setProfileContext({user_id: (e.initiator_id ? e.initiator_id : e.user_id)})
	}

	const [cancelEventApprove, setCancelEventApprove] = useState(false)
	const handleShowCancelApprove = () => setCancelEventApprove(true)
	const handleHideCancelApprove = () => setCancelEventApprove(false)

	const eventContext = useSelector(state => state.events.curr_event_context)
	const authorized = useSelector(state=> state.auth.authorized)
	const [repContext, setRepContext] = useState(null)
	const dispatch = useDispatch()

	let event_id = null
	let is_event_invite = false
	if (eventIntent) {
		event_id = eventIntent.event_id
		is_event_invite = eventIntent.intent_type === 'invite'
	} else if (eventData) {
		event_id = eventData.id
	}

	if (eventContext) {
		is_event_invite = (is_event_invite || !!eventContext.invited)
	}

	useEffect(() => {
		if (event_id) {
			dispatch(fetchEventByID(event_id))
		}
		if (authorized) {
			dispatch(fetchFriendList())
		}
	}, [reload])

	if (!eventContext) {
		return (
			<Box sx={{width: '100%', height: '350px'}}>
				<LoadingButton fullWidth size="large" disabled loading={true}>Loading</LoadingButton>
			</Box>
		)
	}

	const tags = []
	if (eventContext.tags) {
		for (let i = 0; i < eventContext.tags.length; i++) {
			tags.push({key: i, val: eventContext.tags[i]})
		}
	}
	let delta_sec = Math.floor(eventContext.event_ts - Date.now()/1000)
	const already_started = (delta_sec <= 0)
	delta_sec = Math.abs(delta_sec)
	let remain = null
	if (delta_sec > 31536000) {
		remain = String(Math.floor(delta_sec / 31536000)) + ' ' + t('y_')
	} else if (delta_sec > 5184000) {
		remain = String(Math.floor(delta_sec / 2592000)) + ' ' + t('m_')
	} else if (delta_sec > 86400) {
		remain = String(Math.floor(delta_sec / 86400)) + ' ' + t('d_')
	} else if (delta_sec > 3600) {
		remain = String(Math.floor(delta_sec / 3600)) + ' ' + t('h_')
	} else {
		remain = String(Math.floor(delta_sec / 60)) + ' ' + t('min_')
	}

	const eventInteractable = (
		eventContext &&
		eventContext.status !== 'cancelled' &&
		authorized &&
		!already_started
	)

	const ack_min_limit = -600
	const ack_max_limit = 86400

	let avatar_color = '#2d8fd0'
	if (eventContext.organizer_is_me) avatar_color = '#3ad4dd'
	else if (eventContext.participating) avatar_color = '#5bbe4b'
	else if (eventContext.status === 'cancelled') avatar_color = '#c84750'

	let evTypeTitle = "unknown"
	if (eventContext.event_type === "couple") evTypeTitle = t('couple')
	else if (eventContext.event_type === "small_group") evTypeTitle = t('small_group')
	else if (eventContext.event_type === "big_group") evTypeTitle = t('big_group')
	else if (eventContext.event_type === "mass_event") evTypeTitle = t('mass_event')

	const hideEvent = async (event_id) => {
		service.post('/event/hide', {'event_id': event_id})
		.then((res) => {
			dispatch(reloadEventListAction({'remove_event': event_id}))
			onClose()
		})
		.catch((err) => {
			// TODO: process 401
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const reportEvent = async (event_id, repText) => {
		service.post('/event/report', {'event_id': event_id, 'report_text': repText})
		.then((res) => {
			dispatch(reloadEventListAction({'remove_event': event_id}))
			onClose()

			dispatch(notifyAction({'notification': 'event_report_notify', 'severity': 'info'}))
		})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	return (
		<Container
			component="main"
			sx={{
				mb: 0,
				mt: 0
			}}
			disableGutters
		>
			<CssBaseline />

			<ProfileCard profileContext={profileContext} onClose={() => setProfileContext(null)} />
			<ParticipantList participantCount={eventContext.intents_count} checkList={eventContext && eventContext.organizer_is_me} eventID={eventContext.id} open={openParticipantList} onClose={() => {setOpenParticipantList(false)}} />

			<ReportModal
				onSubmit={reportEvent}
				eventContext={repContext}
				onClose={()=>{setRepContext(null)}}
			/>
			<CancelModal
				onSubmit={() => {dispatch(cancelEvent({'event_id': eventContext.id}))}}
				open={cancelEventApprove}
				onClose={handleHideCancelApprove}
			/>

			<Modal
				open={friendListOpen}
				onClose={handleCloseFriendList}
			>
				<Box>
					<FriendList onClose={handleCloseFriendList} eventContextID={eventContext.id} checkList hideInvites userContextHandler={userContextHandler} />
				</Box>
				
			</Modal>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					bgcolor: 'white',
					borderRadius: 2,
					width: '100%',
				}}
			>
				<Grid container spacing={0.4}>
					{eventContext.status === 'cancelled' &&
					<Grid item xs>
						<Chip icon={<WrongLocationRoundedIcon />} label={t('cancelled')} color="error" />
					</Grid>}
					{(eventContext.status !== 'cancelled' && eventContext.acknowledge_status) &&
					<Grid item xs>
						<Chip icon={<CheckCircleRoundedIcon />} label={t('confirmed')} color="success" />
					</Grid>}
					{(eventContext.status !== 'cancelled' && already_started) &&
					<Grid item xs>
						<Chip icon={<PlayArrowRoundedIcon />} label={t('started')} color="warning" />
					</Grid>}
					{(eventContext.status !== 'cancelled' && is_event_invite) &&
					<Grid item xs>
						<Chip icon={<PersonAddRoundedIcon />} label={t('you_are_invited')} color="info" />
					</Grid>}
				</Grid>

				<Grid
					container
					sx={{
						pb: 2,
						pt: 1,
						pl: 2,
						m: 0
					}}
				>
					<Grid item xs={2}>
						<Avatar
							alt="Ava"
							sx={{
								bgcolor: avatar_color
							}}
						>
							<PlaceRoundedIcon />
						</Avatar>
					</Grid>
					<Grid item xs={10}>
						<Typography sx={{ml:2}} variant="h6">{eventContext.title}</Typography>
					</Grid>
				</Grid>

				{/*<Grid item xs={12}>*/}
					{/*<Divider flexItem />*/}
				{/*</Grid>*/}

				<Grid container sx={{mb: 0}}>
					<Grid container>
						<Grid
							container
							item
							xs={12}
							alignItems="center"
							sx={{
								bgcolor: 'white',
								borderLeft: '1px solid #cdd7dd',
								borderBottom: '1px solid #cdd7dd',
								// borderRight: '1px solid #cdd7dd',
								borderBottomLeftRadius: 6,
								// borderBottomRightRadius: 6,
								p: 0,
								m: 0,
								mb: '4px',
								pl: 1,
							}}
						>
							<Grid item xs>
								<Typography sx={{color: '#5d5d5d'}} variant="body2">{t('event_type') + ':'}</Typography>
							</Grid>
							<Grid item xs>
								<Typography sx={{color: '#5d5d5d'}} variant="body2">{evTypeTitle}</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							alignItems="center"
							sx={{
								bgcolor: (already_started ? '#fff4c9' : 'white'),
								borderLeft: '1px solid #cdd7dd',
								borderBottom: '1px solid #cdd7dd',
								borderBottomLeftRadius: 6,
								p: 0,
								m: 0,
								mb: '4px',
								pl: 1,
							}}
						>
							<Grid item xs>
								{eventContext.status !== 'cancelled' &&
									<Typography sx={{color: '#5d5d5d'}} variant="body2">
										{(!already_started ? t('remain') : t('started')) + ':'}
									</Typography>
								}
								{eventContext.status === 'cancelled' &&
									<Typography sx={{color: '#5d5d5d'}} variant="body2">
										-
									</Typography>
								}
							</Grid>
							<Grid item xs>
								{eventContext.status !== 'cancelled' &&
									<Typography sx={{color: '#5d5d5d'}} variant="body2">{remain}</Typography>
								}
								{eventContext.status === 'cancelled' &&
									<Typography sx={{color: '#5d5d5d'}} variant="body2">--:--</Typography>
								}
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							alignItems="center"
							sx={{
								bgcolor: 'white',
								borderLeft: '1px solid #cdd7dd',
								borderBottom: '1px solid #cdd7dd',
								borderBottomLeftRadius: 6,
								p: 0,
								m: 0,
								pl: 1,
							}}
						>
							<Grid item xs>
								{eventContext.status !== 'cancelled' &&
									<Typography sx={{color: '#5d5d5d'}} variant="body2">{t('intended') + ': '}</Typography>
								}
								{eventContext.status === 'cancelled' &&
									<Typography sx={{color: '#5d5d5d'}} variant="body2">{t('participants') + ': '}</Typography>
								}
							</Grid>
							<Grid item xs>
								<Link
									onClick={() => {
										if (eventContext.intents_count > 0) {
											setOpenParticipantList(true)
										}
									}}
									href="#"
									sx={{color: '#5d5d5d'}}
									variant="body2">{String(!!eventContext.intents_count ? eventContext.intents_count : 0) + ' ' + t('peop')}
								</Link>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					container
					columnSpacing={0.4}
					rowSpacing={0.4}
					sx={{
						mt:0,
						borderRadius: 2,
						width: '100%'
					}}
				>
					{tags.map(item => {
						return (
							<Grid item xs={4} key={item.key}>
								<Chip
									sx={{
										height: '20px',
										fontSize: '12px',
										width: '100%',
									}}
									label={t(item.val)}
								/>
							</Grid>
						)
					})}
				</Grid>

				<Grid
					container
					spacing={1.2}
					sx={{
						mt:1,
					}}
				>
					<Grid item xs={4}>
						<Typography variant="body2">{t('event_visible_to')}:</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="body2">{t(eventContext.visible_to)}</Typography>
					</Grid>
					<Grid item xs={4}>
						{eventInteractable &&
							<CopyToClipboard
								text={`${window.location.origin}/event/${eventContext.id}`}
								onCopy={() => {
									dispatch(notifyAction({'notification': 'event_link_copied_notify', 'severity': 'info'}))
								}}
							>
								<Chip size="small" icon={<ShareRoundedIcon />} label={t('share_link')} />
							</CopyToClipboard>
						}
					</Grid>

					<Grid item xs={4}>
						<Typography variant="body2">{t('organizer')}:</Typography>
					</Grid>
					<Grid
						item
						sx={{
							display: 'flex'
						}}
						xs={8}
					>
						<Link
							onClick={() => {
								setProfileContext({user_id: eventContext.organizer})
							}}
							href="#"
							variant="body2"
						>
							{eventContext.organizer_name}
						</Link>
					</Grid>

					<Grid item xs={4}>
						<Typography variant="body2">{t('date')}:</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography variant="body2">{eventContext.original_event_dt}</Typography>
					</Grid>

					{showLocation &&
						<>
							<Grid item xs={4}>
								<Typography variant="body2">{t('location')}:</Typography>
							</Grid>
							<Grid item xs={8}>
								<Button
									variant="contained"
									size="small"
									sx={{
										height: 20,
										top: -4
									}}
									// TODO: move dispatch directly here
									onClick={()=>{onShowEventLocation(event_id)}}
								>
									{t('show_on_map')}
								</Button>
							</Grid>
						</>
					}

					<Grid item xs={12}>
						<TextField
							sx={{

							}}
							fullWidth
							multiline
							readOnly
							rows={4}
							value={eventContext.desc ? eventContext.desc : ''}
							inputProps={{
								readOnly: true,
								disabled: true
							}}
						/>
					</Grid>

					{eventInteractable &&
					<Grid item xs={12}>
						<Stack
							direction="row"
							spacing={1}
							justifyContent="center"
							alignItems="center"
						>
							{(!eventContext.organizer_is_me && !eventContext.participating && !is_event_invite) &&
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										dispatch(createIntent({'event_id': eventContext.id}))
										dispatch(setMyIntentOnEventContextAction())
									}}
									startIcon={<HowToRegRoundedIcon />}
								>
									{t('intent')}
								</Button>
							}

							{(!eventContext.organizer_is_me && !eventContext.participating && !is_event_invite) &&
								<Button
									variant="outlined"
									color="primary"
									onClick={async () => {await hideEvent(eventContext.id)}}
									startIcon={<VisibilityOffRoundedIcon />}
								>
									{t('hide')}
								</Button>
							}

							{(is_event_invite && !eventContext.organizer_is_me && !eventContext.participating) &&
								<>
									<Button
										startIcon={<CheckRoundedIcon />}
										variant="contained"
										color="primary"
										onClick={async () => {
											await service.post("/event/invite/accept", {
												'event_id': event_id
											})
											.then((res) => {
												dispatch(fetchMyIntents())
												setReload(val => !val)
												// dispatch(notifyAction({'notification': 'invite_accepted_notify'}))
											})
											.catch((err) => {
												dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
											})
										}}
									>
										{t('accept')}
									</Button>

									<Button
										startIcon={<CloseRoundedIcon />}
										variant="outlined"
										color="primary"
										onClick={async () => {
											await service.post("/event/invite/decline", {
												'event_id': event_id
											})
											.then((res) => {
												dispatch(fetchMyIntents())
												setReload(val => !val)
												// dispatch(notifyAction({'notification': 'invite_declined_notify', 'severity': 'info'}))
											})
											.catch((err) => {
												dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
											})
											onClose()
										}}>
										{t('decline')}
									</Button>
								</>
							}

							{(eventContext.organizer_is_me || eventContext.participating) &&
								<Button
									variant="contained"
									color="info"
									onClick={handleOpenFriendList}
									startIcon={<PersonAddRoundedIcon />}
								>
									{t('invite')}
								</Button>
							}

							{eventContext.organizer_is_me &&
								<Button
									variant="outlined"
									color="primary"
									onClick={() => {
										dispatch(setEventEditStateAction({'id': event_id}))
									}}
									startIcon={<EditLocationAltRoundedIcon />}
								>
									{t('edit')}
								</Button>
							}

							{eventContext.organizer_is_me &&
								<Button
									variant="outlined"
									color="primary"
									onClick={handleShowCancelApprove}
									startIcon={<DeleteForeverRoundedIcon />}
								>
									{t('cancel_event')}
								</Button>
							}

							{(!eventContext.organizer_is_me && eventContext.participating) &&
								<Button
									variant="outlined"
									color="primary"
									onClick={() => {
										dispatch(removeIntent({'event_id': eventContext.id}))
										dispatch(removeMyIntentOnEventContextAction())
										dispatch(fetchMyIntents())
									}}
									startIcon={<WrongLocationRoundedIcon />}
								>
									{t('refuse')}
								</Button>
							}
						</Stack>

						{(eventContext.organizer_is_me && !eventContext.acknowledge_status && delta_sec > ack_min_limit && delta_sec < ack_max_limit) &&
							<Stack
								direction="row"
								spacing={1}
								justifyContent="center"
								alignItems="center"
								sx={{mt:3, mb:0}}
							>
								<Button
									startIcon={<AssignmentTurnedInRoundedIcon />}
									variant="contained"
									color="success"
									onClick={() => {
										dispatch(ackEvent({'event_id': eventContext.id}))
									}}
								>
									{t('ack_event')}
								</Button>
							</Stack>
						}

						{(!eventContext.organizer_is_me && !eventContext.participating) &&
							<Stack
								direction="row"
								spacing={1}
								justifyContent="center"
								alignItems="center"
								sx={{mt:3, mb:0}}
							>
								<Button
									variant="outlined"
									color="error"
									onClick={() => {
										setRepContext(eventContext)
									}}
									startIcon={<ReportRoundedIcon />}
								>
									{t('report')}
								</Button>
							</Stack>
						}
					</Grid>
					}
				</Grid>

			</Box>

		</Container>
	)
}

function CancelModal({open, onSubmit, onClose}) {
	const {t} = useTranslation()

	const handleSubmit = () => {
		onSubmit()
		onClose()
	}

	return (
		<Modal
			open={open}
			onClose={onClose}
		>
			<Box
				sx={{
					maxWidth: 400,
					bgcolor: '#fafafa',
					position: 'absolute',
					top: '30%',
					left: '10%',
					width: '80%',
					borderRadius: '6px',
					boxShadow: 24,
					p: 1
				}}
				component="form"
				onSubmit={handleSubmit}
			>
				<Grid container spacing={1} alignItems="center" justifyContent="center">

					<Grid item xs={12}>
						<Typography align="center" variant="h5">{t('cancel_event_approve_title')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center" sx={{color: '#626262'}} variant="h6">{t('are_you_sure')}</Typography>
					</Grid>

					<Grid sx={{mt:2, mb: 1}} alignItems="center" justifyContent="center" container item xs={12} spacing={1}>
						<Grid item xs={5}>
							<Button sx={{width: '120px'}} type="submit" key="cancel-event" size="small" startIcon={<CheckRoundedIcon />} variant="contained" color="info">
								{t('accept')}
							</Button>
						</Grid>
						<Grid item xs={5}>
							<Button sx={{width: '120px'}} type="reset" key="cancel-event-cancel" size="small" variant="outlined" color="error" onClick={onClose}>
								{t('cancel')}
							</Button>
						</Grid>
					</Grid>

				</Grid>

			</Box>
		</Modal>
	)
}