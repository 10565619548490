import './App.css';
import {useState, useEffect, Fragment} from 'react'
import {setEventViewStateAction} from 'store/slice/states'
import {useDispatch} from 'react-redux'
import Map from './components/Map/Map'
import MenuButton from './components/MenuButton/MenuButton'
import ProfileMenu from './components/ProfileMenu/ProfileMenu'
import EventContext from './components/EventContext/EventContext'
import AcceptMarkerPosition from './components/AcceptMarkerPosition/AcceptMarkerPosition'
import NewEventButton from './components/NewEventButton/NewEventButton'
import MainMenu from './components/MainMenu/MainMenu'
import EventSlider from './components/EventSlider/EventSlider'
import NotifyWidget from './components/NotifyWidget/NotifyWidget'
import LocalePannel from './components/LocalePannel/LocalePannel'

import {createTheme, ThemeProvider} from '@mui/material/styles'
import {ruRU} from '@mui/material/locale'
import i18n from "i18next"
import {initReactI18next} from 'react-i18next'
import locResource from 'localization'


const theme = createTheme(
  {
    palette: {
      primary: {main: '#1976d2'}
    }
  },
  ruRU
)

i18n.use(initReactI18next).init(locResource)

function App() {
  const dispatch = useDispatch()
  const p = window.location.pathname
  if (p.startsWith('/event/')) {
    const eid = p.substring(7)
    // TODO: validate eid
    dispatch(setEventViewStateAction({'id': eid, 'fly_to_location': true}))
  }
  return (
    <ThemeProvider theme={theme}>
      <Map />
      <EventSlider />
      <MainMenu />
      <LocalePannel />
      <NewEventButton />
      <AcceptMarkerPosition />
      <NotifyWidget />
    </ThemeProvider>
  )
}

export default App;
