import {createSlice, createAction} from "@reduxjs/toolkit"


const initialState = {
	locale: 'ru', // ru/en

	map_context_lng: 51.505,
	map_context_lat: -0.09,
	map_context_zoom: 0,
}

export const setLngAction = createAction('set_map_lng')
const setLngReducer = (state, action) => {
	state.map_context_lng = action.payload
}

export const setLatAction = createAction('set_map_lat')
const setLatReducer = (state, action) => {
	state.map_context_lat = action.payload
}

export const setZoomAction = createAction('set_map_zoom')
const setZoomReducer = (state, action) => {
	state.map_context_zoom = action.payload
}

export const setLocaleRUAction = createAction('set_locale_ru')
const setLocaleRUReducer = (state, action) => {
	state.locale = 'ru'
}

export const setLocaleENAction = createAction('set_locale_en')
const setLocaleENReducer = (state, action) => {
	state.locale = 'en'
}

export const settingsSlice = createSlice({
	name: 'states',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			setLngAction,
			setLngReducer
		)
		builder.addCase(
			setLatAction,
			setLatReducer
		)
		builder.addCase(
			setZoomAction,
			setZoomReducer
		)

		builder.addCase(
			setLocaleRUAction,
			setLocaleRUReducer
		)
		builder.addCase(
			setLocaleENAction,
			setLocaleENReducer
		)
	}
})

export default settingsSlice.reducer