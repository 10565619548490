import {useState, useEffect} from 'react'
import service from "utils/axios"
import {getUniqueIntents} from 'utils/event_list_utils'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import {fetchEventByID} from 'store/thunks/events'
import {useDispatch} from 'react-redux'
import {notifyAction} from 'store/slice/notify'

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListSubheader from '@mui/material/ListSubheader'
import {deepOrange} from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import DeleteIcon from '@mui/icons-material/Delete'

import InfiniteScroll from 'react-infinite-scroll-component'


export default function ParticipantList(props) {
	const [profileContext, setProfileContext] = useState(null)
	const [items, setItems] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const [index, setIndex] = useState(0)
	const [checked, setChecked] = useState([])

	const dispatch = useDispatch()

	const {
		open,
		onClose,
		eventID,
		checkList,
		participantCount
	} = props

	const handleClose = () => {
		setChecked([])
		onClose()
	}

	const handleToggle = (value) => () => {
		const currIndex = checked.indexOf(value)
		const newChecked = [...checked]
		if (currIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currIndex, 1)
		}
		setChecked(newChecked)
	}

	let checkAction = (value) => null
	if (checkList) {
		checkAction = (value) => {
			return (
				<Checkbox
					edge="end"
					onChange={handleToggle(value)}
					checked={checked.indexOf(value) !== -1}
					inputProps={{'aria-labelledby': `checkbox-${value}`}}
				/>
		)}
	}


	const handleRemoveIntents = async () => {
		const plist = []
		for (let i=0; i < checked.length; ++i) {
			plist.push(items[checked[i]].user_id)
		}
		if (plist.length > 0) {
			// TODO: toast plist limit overflow
			service.post("/event/intent/remove/batch", {
				'event_id': eventID,
				'user_list': plist.slice(0, 30)
			})
			.then((res) => {
				// TODO: success toast
				setIndex(0)
				setItems([])
				// setHasMore(true)
				setChecked([])
				dispatch(fetchEventByID(eventID))
				onClose()
			})
			.catch((err) => {
				dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
			})
		}
	}

	useEffect(() => {
		service.post("event/intent/list", {
			'event_id': eventID,
			'limit': 20,
			'offset': index
		})
		.then((res) => {
			setItems(res.data)
			setIndex(res.data.length)
			setHasMore(res.data.length > 0)
		})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}, [participantCount])

	const fetchMore = () => {
		service.post("event/intent/list", {
			'event_id': eventID,
			'limit': 20,
			'offset': index
		})
		.then((res) => {
			let idx = index
			setItems((prevItems) => {
				const newItems = getUniqueIntents(prevItems, res.data)
				// const newItems = [...prevItems, ...res.data]
				idx = newItems.length
				return newItems
			})
			setIndex(idx)
			if (res.data.length > 0) setHasMore(true);
			else					 setHasMore(false);
		})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
		>
			<Box
				sx={{
					maxWidth: 400,
					bgcolor: '#fafafa',
					position: 'absolute',
					top: '30%',
					left: '10%',
					width: '80%',
					bottom: '100px',
					borderRadius: '6px',
					boxShadow: 24,
					p: 1
				}}
			>
				<ProfileCard profileContext={profileContext} onClose={() => setProfileContext(null)} />

				<List
					id="scrollable-participant-list"
					dense
					sx={{
						width: '100%',
						left: 0,
						height: '100%',
						overflow: 'auto',
						bgcolor: '#f9f9f9',
						// border: 'solid 2px #f1f1f1',
						borderRadius: 2,
					}}
				>

					<InfiniteScroll
						dataLength={items.length}
						next={fetchMore}
						hasMore={hasMore}
						loader={<h4>Loading...</h4>}
						// height="100%"
						scrollableTarget="scrollable-participant-list"
					>
							{items.map((item, index) => (
								// <Typography variant="h6">{user.name}</Typography>
								<ListItem
									key={String(item.id) + "-event-itent"}
									secondaryAction={checkAction(index)}
								>
									<ListItemButton onClick={()=>{setProfileContext(item)}}>
										<ListItemAvatar>
											<Avatar sx={{bgcolor: '#3f9fdf'}}>{item.user_name.substring(0,1)}</Avatar>
										</ListItemAvatar>
										<ListItemText primary={item.user_name} />
									</ListItemButton>
								</ListItem>
							))}
					</InfiniteScroll>
				</List>


				{checked.length > 0 &&
					<Box
						sx={{
							// bgcolor: 'white',
							borderRadius: 1,
							// border: '1px solid #7a7a7a',
							p:1,
							mb:2,
							mt:2,
						}}
					>
						<Stack
							direction="row"
							spacing={1}
							justifyContent="center"
							alignItems="center"
						>
							<Button
								variant="contained"
								startIcon={<PersonRemoveRoundedIcon />}
								onClick={handleRemoveIntents}
							>
								Remove
							</Button>
							<Button
								variant="outlined"
								startIcon={<DeleteIcon />}
								onClick={() => {
									setChecked([])
								}}
								sx={{bgcolor: 'white'}}
							>
								Cancel
							</Button>
						</Stack>
					</Box>
				}
			</Box>
		</Modal>
	)
}