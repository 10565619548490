import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {notifyAction, dropNotifyAction} from 'store/slice/notify'
import {useTranslation} from 'react-i18next'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'


export default function NotifyWidget() {
	const notifyMessage = useSelector(state => state.notify.notification)
	const notifySeverity = useSelector(state => state.notify.severity)
	const notifyDuration = useSelector(state => state.notify.duration)
	const [open, setOpen] = useState(false)
	const [notify, setNotify] = useState(null)
	const dispatch = useDispatch()
	const {t} = useTranslation()

	useEffect(() => {
		if (notifyMessage) {
			setNotify(notifyMessage)
			setOpen(true)
		}
	}, [notifyMessage])

	return (
		<Snackbar
			open={open}
			onClose={() => {dispatch(dropNotifyAction()); setOpen(false)}}
			autoHideDuration={notifyDuration}
		>
			<Alert
				onClose={() => {dispatch(dropNotifyAction()); setOpen(false)}}
				variant="filled"
				severity={notifySeverity}
			>
				{t(notify)}
			</Alert>
		</Snackbar>
	)
}