import classes from './ProfileScreen.module.css'
import {useSelector, useDispatch} from 'react-redux'
import {useEffect, useState} from 'react'
import {getMe, fetchFriendList} from 'store/thunks/user'
import {fetchMyIntents} from 'store/thunks/events'
import {logoutAction} from 'store/slice/auth'
import {closeEventContextAction} from 'store/slice/events'
import {setEventViewStateAction} from 'store/slice/states'
import {useTranslation} from 'react-i18next'

import Badge from '@mui/material/Badge'
import Modal from '@mui/material/Modal'
import EventList from 'components/EventList/EventList'
import EventContext from 'components/EventContext/EventContext'
import FriendList from 'components/FriendList/FriendList'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import SupportModal from 'components/SupportModal/SupportModal'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'


function EventContextModal(props) {
	const {eventContext, onClose} = props
	const dispatch = useDispatch()

	const handleShowEventLocation = (event_id) => {
		dispatch(setEventViewStateAction({'id': event_id, 'fly_to_location': true}))
	}

	return (
		<Modal
			open={!!eventContext}
			onClose={() => {
				dispatch(closeEventContextAction())
				onClose()
			}}
		>
			<Box
				sx={{
					maxWidth: 360,
					bgcolor: 'white',
					position: 'absolute',
					top: '10%',
					left: '5%',
					width: '90%',
					borderRadius: '6px',
					boxShadow: 24,
					p: 4,
				}}
			>
				<EventContext onClose={onClose} showLocation eventIntent={eventContext} onShowEventLocation={handleShowEventLocation} />
			</Box>
		</Modal>
	)
}


export default function ProfileScreen({stateHandler, open}) {
	const [eventListOpen, setEventListOpen] = useState(false)
	const handleOpenEventList = () => setEventListOpen(true)
	const handleCloseEventList = () => setEventListOpen(false)

	const [eventInvitesOpen, setEventInvitesOpen] = useState(false)
	const handleOpenEventInvites = () => setEventInvitesOpen(true)
	const handleCloseEventInvites = () => setEventInvitesOpen(false)

	const [eventContext, setEventContext] = useState(null)

	const eventContextHandler = (e) => {
		setEventContext(e)
	}

	const [supportModalOpen, setSupportModalOpen] = useState(false)
	const handleCloseSupportModal = () => setSupportModalOpen(false)
	const handleOpenSupportModal = () => setSupportModalOpen(true)

	const [profileContext, setProfileContext] = useState(null)
	const [friendListOpen, setFriendListOpen] = useState(false)
	const handleOpenFriendList = () => setFriendListOpen(true)
	const handleCloseFriendList = () => setFriendListOpen(false)
	const userContextHandler = (e) => {
		setProfileContext({user_id: (e.initiator_id ? e.initiator_id : e.user_id)})
	}

	const userInfo = useSelector(state => state.auth.user)
	const intents = useSelector(state => state.events.intent_list)
	const dispatch = useDispatch()
	const {t} = useTranslation()

	let eventsCount = 0
	if (intents && intents.organized) eventsCount += intents.organized.length
	if (intents && intents.participating) eventsCount += intents.participating.length
	let invitesCount = 0
	if (intents && intents.invites) invitesCount += intents.invites.length

	useEffect(() => {
		dispatch(getMe())
		dispatch(fetchMyIntents())
		dispatch(fetchFriendList())
	}, [])

	return (
		<Container component="main" maxWidth="xs" sx={{mb: 2}}>
			<CssBaseline />

			<Modal
				open={eventListOpen && open}
				onClose={handleCloseEventList}
			>
				<Box>
					<EventContextModal onClose={()=>{setEventContext(null)}} eventContext={eventContext} />
					<EventList organizerSection participateSection eventContextHandler={eventContextHandler} />
				</Box>
				
			</Modal>

			<Modal
				open={eventInvitesOpen && open}
				onClose={handleCloseEventInvites}
			>
				<Box>
					<EventContextModal onClose={()=>{setEventContext(null)}} eventContext={eventContext} />
					<EventList invitesSection eventContextHandler={eventContextHandler} />
				</Box>
				
			</Modal>

			<Modal
				open={supportModalOpen && open}
				onClose={handleCloseSupportModal}
			>
				<Box>
					<SupportModal />
				</Box>
			</Modal>

			<Modal
				open={friendListOpen && open}
				onClose={handleCloseFriendList}
			>
				<Box>
					<ProfileCard
						profileContext={profileContext}
						onClose={() => {
							setProfileContext(null)
							dispatch(fetchFriendList())
						}}
					/>
					<FriendList userContextHandler={userContextHandler} />
				</Box>
				
			</Modal>

			<Box
				sx={{
					marginTop: 2,
					bgcolor: 'white',
					borderRadius: 2
				}}
			>
				<ListItem sx={{mb: 3}}>
					<ListItemAvatar>
						<Avatar alt="Ava" src="" />
					</ListItemAvatar>
					<ListItemText
						primary={userInfo.name}
						secondary={userInfo.email}
					/>
				</ListItem>
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					bgcolor: 'white',
					borderRadius: 2
				}}
			>
				<ListItemButton onClick={()=>{stateHandler('ProfileSettings')}} key="profile-settings" sx={{borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
					<ListItemIcon>
						<AccountBoxRoundedIcon />
					</ListItemIcon>
					<ListItemText primary={t('profile_settings')} />
				</ListItemButton>

				<ListItemButton onClick={handleOpenEventList} key="events" sx={{}}>
					<ListItemIcon>
						<Badge
							color="success"
							invisible={!eventsCount}
							badgeContent={eventsCount}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<CalendarMonthRoundedIcon />
						</Badge>
					</ListItemIcon>
					<ListItemText primary={t('events')} />
				</ListItemButton>

				<ListItemButton onClick={handleOpenEventInvites} key="invites" sx={{}}>
					<ListItemIcon>
						<Badge
							color="secondary"
							invisible={!invitesCount}
							badgeContent={invitesCount}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<AddLocationAltRoundedIcon />
						</Badge>
					</ListItemIcon>
					<ListItemText primary={t('invites')} />
				</ListItemButton>

				<ListItemButton onClick={handleOpenFriendList} key="friends" sx={{}}>
					<ListItemIcon>
						<Badge
							invisible={!userInfo.friend_invite_list || userInfo.friend_invite_list.length == 0}
							color="primary"
							badgeContent={userInfo.friend_invite_list ? userInfo.friend_invite_list.length : 0}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<GroupRoundedIcon />
						</Badge>
					</ListItemIcon>
					<ListItemText primary={t('friends')} />
				</ListItemButton>

				{/* TODO: FOLLOWER FEATURE
				<ListItemButton key="followers" sx={{}}>
					<ListItemIcon>
						<GroupsRoundedIcon />
					</ListItemIcon>
					<ListItemText primary="Followers" />
				</ListItemButton>
				*/}

				<ListItemButton onClick={handleOpenSupportModal} key="support" sx={{mt: 2}}>
					<ListItemIcon>
						<SupportAgentRoundedIcon />
					</ListItemIcon>
					<ListItemText primary={t('support') + '/' + t('contacts')} />
				</ListItemButton>

				<ListItemButton onClick={()=>{dispatch(logoutAction())}} key="logout" sx={{mt: 2, borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}>
					<ListItemIcon>
						<LogoutRoundedIcon />
					</ListItemIcon>
					<ListItemText primary={t('logout')} />
				</ListItemButton>

			</Box>
		</Container>
	)
}
