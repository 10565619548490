import classes from './LoginScreen.module.css'
import {useTranslation} from 'react-i18next'
import {notifyAction} from 'store/slice/notify'

import CredInput from 'components/CredInput/CredInput'
import {useDispatch, useSelector} from 'react-redux'
import {loginUser} from "store/thunks/auth"
import {useState, useEffect} from 'react'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'


export default function LoginScreen({stateHandler}) {
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const loading = useSelector((state) => {
		return state.auth.pending
	})

	const submitHandler = (e) => {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (!data.get('email') || !data.get('password')) {
			dispatch(notifyAction({'notification': 'invalid_user_email_or_pass_notify', 'severity': 'error'}))
		} else {
			dispatch(loginUser({
				email: data.get('email'),
				password: data.get('password')
			}))
		}
	}

	return (
		<Container component="main" maxWidth="xs" sx={{mb: 10}}>
			<CssBaseline />

			<Box
				sx={{
					marginTop: 1,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<Avatar sx={{m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>

				<Typography component="h1" variant="h5">
					{t('signin')}
				</Typography>

				<Box component="form" noValidate onSubmit={submitHandler} sx={{mt:1}}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label={t('email_address')}
						name="email"
						inputProps={{
							type: "email"
						}}
						autoComplete="email"
						autoFocus
						sx={{bgcolor: 'white', borderRadius: 1}}
					/>

					<TextField
						margin="normal"
						required
						fullWidth
						id="password"
						label={t('password')}
						type="password"
						name="password"
						autoComplete="current-password"
						sx={{bgcolor: 'white', borderRadius: 1}}
					/>

					<LoadingButton
						type="submit"
						fullWidth
						variant="contained"
						sx={{mt:3,mb:2}}
						loading={loading}
					>
						{t('signin')}
					</LoadingButton>

					<Grid container>
						<Grid item xs>
							<Link href="#" variant="body2" onClick={()=>{stateHandler('RestorePassword')}}>
								{t('forgot_password')}
							</Link>
						</Grid>
						<Grid item>
							<Link href="#" variant="body2" onClick={()=>{stateHandler('Register')}}>
								{t('signup')}
							</Link>
						</Grid>
					</Grid>
				</Box>

			</Box>

		</Container>
	)
}
