import service from "utils/axios"
import {createAsyncThunk} from "@reduxjs/toolkit"


export const getMe = createAsyncThunk(
	'current_user',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.get('user/me')
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const updateMe = createAsyncThunk(
	'update_current_user',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('user/me/update', data)
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const fetchFriendList = createAsyncThunk(
	'fetch_friend_list',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.get('/user/friend_list')
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)