

export function mergeEvents(from, to, geofilter=null) {
	// if (to.length > 500) {
	// 	to = from
	// 	return
	// }

	const presented = new Set()
	for (let v of from) {
		presented.add(v.id)
	}
	to.push(...from)
	const exists = new Set()
	for (let i = to.length - 1; i >= 0; --i) {
		if (exists.has(to[i].id)) {
			to.splice(i, 1)
			continue
		} else {
			exists.add(to[i].id)
		}

		if (geofilter) {
			if (to[i].lng >= geofilter.lng_from && to[i].lng <= geofilter.lng_to && to[i].lat >= geofilter.lat_from && to[i].lat <= geofilter.lat_to && !presented.has(to[i].id)) {
				to.splice(i, 1)
			}
		}
	}
}

export function getUniqueIntents(prev, next) {
	const res = []
	const exists = new Set()
	for (let v of prev) {
		if (!exists.has(v.id)) {
			res.push(v)
			exists.add(v.id)
		}
	}
	for (let v of next) {
		if (!exists.has(v.id)) {
			res.push(v)
			exists.add(v.id)
		}
	}
	return res
}

export function mergeIntents(from, to) {
	to.push(...from)
	const exists = new Set()
	for (let i = to.length - 1; i >= 0; --i) {
		if (exists.has(to[i].event_id)) {
			to.splice(i, 1)
		} else {
			exists.add(to[i].event_id)
		}
	}
}

export function generateGeoJSONData(event_list) {
	const res = {
		'type': 'FeatureCollection',
		'features': []
	}
	for (let event of event_list) {
		res.features.push({
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [
					event.lng,
					event.lat
				]
			},
			'properties': {
				'event_id': event.id
			}
		})
		
	}
	return res
}

export function generateGeoJSON(event_list) {
	const result = {
		'type': 'geojson',
		// 'cluster': true,
		// 'clusterRadius': 80,
		'data': generateGeoJSONData(event_list)
	}
	return result
}

export function draftFromEventContext(event_context) {
	if (!event_context) {
		return null
	}
	const result = {
		title: event_context.title,
		event_type: event_context.event_type,
		visible_to: event_context.visible_to,
		tags: event_context.tags,
		event_date: event_context.original_event_dt.substring(0, 10),
		event_time: event_context.original_event_dt.substring(11),
		desc: event_context.desc,
		group: event_context.group,
		lng: event_context.lng,
		lat: event_context.lat,
		related_event: event_context.id
	}
	return result
}