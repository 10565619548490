import classes from './ProfileSettingsScreen.module.css'
import {useSelector, useDispatch} from 'react-redux'
import {updateMe, getMe} from 'store/thunks/user'
import {useState, useEffect, useRef} from 'react'
import Input from '@mui/material/Input'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import Switch from '@mui/material/Switch'
import FormControl, {useFormControl} from '@mui/material/FormControl'
import dayjs from 'dayjs'
import {styled} from '@mui/material/styles'
import service from "utils/axios"
import {useTranslation} from 'react-i18next'
import {notifyAction} from 'store/slice/notify'

import Fab from '@mui/material/Fab'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Modal from '@mui/material/Modal'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LockResetRoundedIcon from '@mui/icons-material/LockResetRounded'


const FileInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
})

function ChangableWidget(props) {
	const {fieldProps, fieldGridProps, buttonProps, buttonGridProps, attrText, onChange} = props
	const [changeMode, updateChangeMode] = useState(false)
	const [content, updateContent] = useState(<></>)
	const attr = useRef(attrText)
	const {t} = useTranslation()


	const handleSubmit = (e) => {
		e.preventDefault()
		updateChangeMode(false)
		if (attrText !== attr.current && !!attr.current)
		{
			onChange(attr.current)
		}
	}

	useEffect(() => {
		if (changeMode)
		{
			updateContent(
				<Grid item container xs={12} sx={{
					borderRadius: 3,
					// bgcolor: '#d3d3d3',
					border: '1px solid #d3d3d3',
					p: 2
				}}>
					<Box component="form" noValidate onSubmit={handleSubmit}>
						<Grid item container xs={12} spacing={2}>
							<Grid item xs={8}>
								<Typography variant="h6">{t('name')}:</Typography>
								<TextField
									fullWidth
									sx={{bgcolor: 'white', borderRadius: 1}}
									defaultValue={attr.current}
									onChange={(e) => {attr.current = e.target.value}}
								/>
							</Grid>

							<Grid item xs={4} sx={{mb:1}}>
								<Button
									type="submit"
									size="medium"
									variant="contained"
									color="info"
									sx={{mb: 2}}
								>
									{t('accept')}
								</Button>

								<Button
									type="reset"
									size="medium"
									variant="outlined"
									color="info"
									onClick={()=>{
										attr.current = attrText
										updateChangeMode(false)
									}}
								>
									{t('cancel')}
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			)
		} else {
			updateContent(
				<>
					<Grid {...fieldGridProps} item>
						<Typography {...fieldProps}>
							{attrText}
						</Typography>
					</Grid>
					<Grid {...buttonGridProps} item>
						<Button {...buttonProps} color="info" onClick={()=>{updateChangeMode(true)}}
						>
							{t('change')}
						</Button>
					</Grid>
				</>
			)
		}
	},[changeMode, attrText])

	return <>{content}</>
}

export default function ProfileSettingsScreen({stateHandler}) {
	const [openEmailChange, setOpenEmailChange] = useState(false)
	const [openPassChange, setOpenPassChange] = useState(false)
	const userInfo = useSelector(state => state.auth.user)
	const dispatch = useDispatch()
	const {t} = useTranslation()

	return (
		<Container component="main" maxWidth="xs"
			sx={{
				mb: 2,
				borderRadius: 3,
				py: 2
			}}
		>
			<CssBaseline />

			<ChangeEmailModal open={openEmailChange} onClose={()=>setOpenEmailChange(false)} />
			<ChangePassModal open={openPassChange} onClose={()=>setOpenPassChange(false)} />

			<Fab
				variant="extended"
				size="small"
				color="primary"
				sx={{
					position:'absolute',
					m: 0,
					left: 16,
					top: 16,
					px: 2,
				}}
				onClick={()=>{stateHandler('Profile')}}
			>
				<KeyboardBackspaceRoundedIcon />
				{t('back')}
			</Fab>

			<Box
				sx={{
					marginTop: 1,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row'
					}}
				>
					<Avatar sx={{m: 1}} />
					{/* TODO: user avatar
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Button
							component="label"
							role={undefined}
							variant="contained"
							tabIndex={-1}
							startIcon={<CloudUploadRoundedIcon fontSize="small" />}
							size="small"
						>
							change
							<FileInput type="file" />
						</Button>
					</Box>
					*/}
				</Box>

			<Box
				sx={{
					marginTop: 1,
					display: 'flex',
					flexDirection: 'column',
					width: '100%'
				}}
			>
				<Grid container spacing={1.5} sx={{mt:1}}>

					<Grid item container spacing={0} sx={{mt:1}}>
						{/* NAME */}
						<ChangableWidget
							fieldGridProps={{xs: true}}
							fieldProps={{variant: 'h6'}}
							buttonGridProps={{xs: true}}
							buttonProps={{
								variant: 'contained',
								size: 'small',
								sx: {height: 20}
							}}
							attrText={userInfo.name}
							onChange={(val) => {
								dispatch(updateMe({name: val}))
							}}
						/>
						<Grid item xs></Grid>
					</Grid>

					{/* EMAIL */}
					<Grid item xs={3} sx={{}}>
						Email:
					</Grid>
					<Grid item xs={5}>
						<Typography variant="body2">{userInfo.email}</Typography>
					</Grid>
					<Grid item xs={4}>
						<Button
							variant="contained"
							size="small"
							color="info"
							sx={{
								height: 20
							}}
							onClick={()=>setOpenEmailChange(true)}
						>
							{t('change')}
						</Button>
					</Grid>

					{/* PASS */}
					<Grid item xs={12} sx={{mb:2}}>
						<Button
							variant="contained"
							size="small"
							color="info"
							startIcon={<LockResetRoundedIcon />}
							onClick={()=>setOpenPassChange(true)}
						>
							{t('change_pass')}
						</Button>
					</Grid>

					{/* PHONE NUMBER */}
		{/*			<Grid item xs={3} sx={{}}>
						Phone:
					</Grid>
					<Grid item xs={5}>
						<Typography variant="body2">+7(777)77-77-777</Typography>
					</Grid>
					<Grid item xs={4}>
						<Button
							variant="contained"
							size="small"
							sx={{
								height: 20
							}}
						>
							change
						</Button>
					</Grid>*/}

					{/* GENDER */}
					<Grid item xs={3}>
						<Typography align="left" sx={{mt:1}}>{t('gender')}</Typography>
					</Grid>
					<Grid item xs={6}>
						<Select
							value={userInfo.gender ? userInfo.gender : ""}
							label={t('gender')}
							onChange={(e) => {dispatch(updateMe({gender: e.target.value}))}}
							variant="standard"
							fullWidth
						>
							<MenuItem value=""><em>{t('unknown')}</em></MenuItem>
							<MenuItem value="male">{t('male')}</MenuItem>
							<MenuItem value="female">{t('female')}</MenuItem>
						</Select>
					</Grid>
					<Grid item xs={3}>
					</Grid>

					{/* BIRTH DATE */}
					<Grid item xs={3}>
						<Typography align="left" sx={{mt:1}}>{t('b_d')}</Typography>
					</Grid>
					<Grid item xs={5}>
						<DatePicker
							value={dayjs(userInfo.birth_date)}
							slotProps={{
								textField: {
									variant: 'standard'
								}
							}}
							onChange={(val) => {
								dispatch(updateMe({birth_date: val.format('YYYY-MM-DD')}))
							}}
						/>
					</Grid>
					<Grid item xs={2} sx={{}}>
						<IconButton onClick={() => {
							dispatch(updateMe({is_birth_date_visible: !userInfo.is_birth_date_visible}))
						}}>
							{userInfo.is_birth_date_visible ? <VisibilityRoundedIcon color="info" /> : <VisibilityOffRoundedIcon />}
						</IconButton>
					</Grid>

					{/* PUBLICITY */}
					<Grid item xs={5}>
						{t('public_profile')}
					</Grid>
					<Grid item xs={7} sx={{}}>
						<Switch
							sx={{top: -7}}
							checked={userInfo.is_public_profile}
							onChange={(val) => {
								dispatch(updateMe({is_public_profile: val.target.checked}))
							}}
						/>
					</Grid>

					{/* ABOUT */}
					<Grid item xs={5}>
						{t('about_user')}
					</Grid>
					<Grid item xs={12}>
						<AboutInput userBio={userInfo.bio} />
					</Grid>
				</Grid>
			</Box>

			</Box>
		</Container>
	)
}

function ChangeEmailModal({open, onClose}) {
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const handleSubmit = async (e) => {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (!data.get('email')) {
			dispatch(notifyAction({
				'notification': 'input_email_notify',
				'severity': 'error'
			}))
		} else if (data.get('email') !== data.get('email-repeat')) {
			dispatch(notifyAction({
				'notification': 'email_not_equal_notify',
				'severity': 'error'
			}))
		} else {
			await service.post("/change/email", {
				'email': data.get('email'),
				'password': data.get('password')
			})
			.then((res) => {
				dispatch(getMe())
				onClose()
			})
			.catch((err) => {
				if (err.response && err.response.status) {
					if (err.response.status === 401) {
						dispatch(notifyAction({
							'notification': 'invalid_password_notify',
							'severity': 'error'
						}))
					} else if (err.response.status === 422) {
						dispatch(notifyAction({
							'notification': 'invalid_user_email_or_pass_notify',
							'severity': 'error'
						}))
					} else if (err.response.status === 400) {
						dispatch(notifyAction({
							'notification': 'email_occupied_notify',
							'severity': 'error'
						}))
					} else {
						dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
					}
				} else {
					dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
				}
			})
		}
	}

	return (
		<Modal
			open={open}
			onClose={() => {
				onClose()
			}}
		>
			<Box
				sx={{
					maxWidth: 360,
					bgcolor: 'white',
					position: 'absolute',
					top: '10%',
					left: '5%',
					width: '90%',
					borderRadius: '6px',
					boxShadow: 24,
					p: 4,
				}}
				component="form"
				onSubmit={handleSubmit}
			>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label={t('new_email')}
					name="email"
					autoComplete="email"
					sx={{bgcolor: 'white', borderRadius: 1}}
				/>

				<TextField
					margin="normal"
					required
					fullWidth
					id="email-repeat"
					label={t('rep_email')}
					name="email-repeat"
					autoComplete="email"
					sx={{bgcolor: 'white', borderRadius: 1}}
				/>

				<TextField
					margin="normal"
					required
					fullWidth
					id="password"
					label={t('curr_pass')}
					type="password"
					name="password"
					autoComplete="current-password"
					sx={{bgcolor: 'white', borderRadius: 1}}
				/>

				<Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
					<Button
						type="submit"
						variant="contained"
						sx={{mt:3,mb:2}}
					>
						{t('submit')}
					</Button>

					<Button
						type="reset"
						variant="outlined"
						sx={{mt:3,mb:2}}
						onClick={onClose}
					>
						{t('cancel')}
					</Button>
				</Stack>
			</Box>
		</Modal>
	)
}

function ChangePassModal({open, onClose}) {
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const handleSubmit = async (e) => {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (!data.get('password-old')) {
			dispatch(notifyAction({
				'notification': 'input_password_notify',
				'severity': 'error'
			}))
		} else if (!data.get('password') || data.get('password') !== data.get('password-repeat')) {
			dispatch(notifyAction({
				'notification': 'passwords_not_equal_notify',
				'severity': 'error'
			}))
		} else {
			await service.post("/change/password", {
				'old_password': data.get('password-old'),
				'password': data.get('password')
			})
			.then((res) => {
				onClose()
			})
			.catch((err) => {
				if (err.response && err.response.status) {
					if (err.response.status === 422) {
						dispatch(notifyAction({
							'notification': 'password_invalid_format_notify',
							'severity': 'error'
						}))
					} else if (err.response.status === 401) {
						dispatch(notifyAction({
							'notification': 'invalid_password_notify',
							'severity': 'error'
						}))
					} else {
						dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
					}
				} else {
					dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
				}
			})
		}
	}

	return (
		<Modal
			open={open}
			onClose={() => {
				onClose()
			}}
		>
			<Box
				sx={{
					maxWidth: 360,
					bgcolor: 'white',
					position: 'absolute',
					top: '10%',
					left: '5%',
					width: '90%',
					borderRadius: '6px',
					boxShadow: 24,
					p: 4,
				}}
				component="form"
				onSubmit={handleSubmit}
			>
				<TextField
					margin="normal"
					required
					fullWidth
					id="password-old"
					label={t('old_pass')}
					type="password"
					name="password-old"
					autoComplete="current-password"
					sx={{bgcolor: 'white', borderRadius: 1}}
				/>

				<TextField
					margin="normal"
					required
					fullWidth
					id="password"
					label={t('new_pass')}
					name="password"
					type="password"
					autoComplete="new-password"
					sx={{bgcolor: 'white', borderRadius: 1}}
				/>

				<TextField
					margin="normal"
					required
					fullWidth
					id="password-repeat"
					label={t('rep_pass')}
					type="password"
					name="password-repeat"
					autoComplete="new-password"
					sx={{bgcolor: 'white', borderRadius: 1}}
				/>

				<Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
					<Button
						type="submit"
						variant="contained"
						sx={{mt:3,mb:2}}
					>
						{t('submit')}
					</Button>

					<Button
						type="reset"
						variant="outlined"
						sx={{mt:3,mb:2}}
						onClick={onClose}
					>
						{t('cancel')}
					</Button>
				</Stack>
			</Box>
		</Modal>
	)
}


function AboutInput(props) {
	const {userBio} = props
	const [bio, setBio] = useState(userBio ? userBio : '')
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const handleSubmit = (e) => {
		e.preventDefault()

		if (userBio !== bio) {
			dispatch(updateMe({bio: bio}))
		}
	}

	let submit = <Button sx={{mt:1}} color="info" variant="contained" type="submit">{t('accept')}</Button>
	let cancel = (
		<Button
			sx={{mt:1, ml:2}}
			color="info"
			variant="outlined"
			type="reset"
			onClick={() => {
				setBio(userBio ? userBio : '')
			}}
		>
				{t('cancel')}
		</Button>)

	return (
		<form onSubmit={handleSubmit}>
			<TextField
				sx={{width: '100%', bgcolor: 'white', borderRadius: 1}}
				multiline
				rows={4}
				value={bio}
				FormHelperTextProps={{style: {backgroundColor: '#f7f7f7', margin: 0}}}
				inputProps={{
					maxLength: 200
				}}
				helperText={`${bio ? bio.length : 0}/${200}`}
				onChange={(e) => {
					setBio(e.target.value)
				}}
			/>
			{(userBio ? userBio : '') !== bio && <>{submit}{cancel}</>}

		</form>
	)
}
