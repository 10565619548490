import {createSlice, createAction} from "@reduxjs/toolkit"
import {regUser, loginUser} from "store/thunks/auth"
import {getMe, updateMe, fetchFriendList} from 'store/thunks/user'

const initialState = {
	user: {},
	authorized: false,

	pending: false
}

export const logoutAction = createAction('logout')
const logoutReducer = (state, action) => {
	state.authorized = false
	state.user = {}
}


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			logoutAction,
			logoutReducer
		)

		builder.addCase(
			getMe.fulfilled,
			(state, action) => {
				state.user = {
					...state.user,
					...action.payload
				}
			}
		)
		builder.addCase(
			getMe.rejected,
			// TODO: only auth error!!
			logoutReducer
		)

		builder.addCase(
			updateMe.fulfilled,
			(state, action) => {
				state.user = {
					...state.user,
					...action.payload
				}
			}
		)

		builder.addCase(
			regUser.pending,
			(state, action) => {
				state.pending = true
			}
		)
		builder.addCase(
			regUser.fulfilled,
			(state, action) => {
				state.user = action.payload
				state.authorized = true
				state.pending = false
			}
		)
		builder.addCase(
			regUser.rejected,
			(state, action) => {
				state.authorized = false
				state.pending = false
			}
		)

		builder.addCase(
			loginUser.pending,
			(state, action) => {
				state.pending = true
			}
		)
		builder.addCase(
			loginUser.fulfilled,
			(state, action) => {
				state.user = action.payload
				state.authorized = true
				state.pending = false
			}
		)
		builder.addCase(
			loginUser.rejected,
			(state, action) => {
				state.authorized = false
				state.pending = false
			}
		)


		builder.addCase(
			fetchFriendList.pending,
			(state, action) => {
				
			}
		)
		builder.addCase(
			fetchFriendList.fulfilled,
			(state, action) => {
				state.user.friend_list = action.payload.friend_list
				state.user.friend_invite_list = action.payload.invites
			}
		)
	}
})

export default authSlice.reducer