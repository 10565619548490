
export class MapLayers {
	constructor(eventList) {
		this.events = {
			couple: {
				standard: [],
				organized: [],
				participating: [],
				invited: [],
				cancelled: []
			},
			small_group: {
				standard: [],
				organized: [],
				participating: [],
				invited: [],
				cancelled: []
			},
			big_group: {
				standard: [],
				organized: [],
				participating: [],
				invited: [],
				cancelled: []
			},
			mass_event: {
				standard: [],
				organized: [],
				participating: [],
				invited: [],
				cancelled: []
			}
		}

		if (eventList && eventList.length > 0) {
			for (let event of eventList) {
				const etype = event.event_type
				// TODO: uncomment when type validation passed
				// const etype = 'couple'
				let ev_geo_item = {
					'type': 'Feature',
					'geometry': {
						'type': 'Point',
						'coordinates': [
							event.lng,
							event.lat
						]
					},
					'properties': {
						'event_id': event.id
					}
				}
				if (event.status === 'cancelled') {
					this.events[etype].cancelled.push(ev_geo_item)
				} else if (event.organizer_is_me) {
					this.events[etype].organized.push(ev_geo_item)
				} else if (event.participating) {
					this.events[etype].participating.push(ev_geo_item)
				} else if (event.invited) {
					this.events[etype].invited.push(ev_geo_item)
				} else {
					this.events[etype] .standard.push(ev_geo_item)
				}
			}
		}
	}

	getGeoJSON(data) {
		return {
			'type': 'geojson',
			// 'cluster': true,
			// 'clusterRadius': 80,
			'data': data,
			// 'attribution': '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
		}
	}

	getLayerGeoData(event_type, event_status) {
		return {
			'type': 'FeatureCollection',
			'features': this.events[event_type][event_status]
		}
	}
}