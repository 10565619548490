import React, { useRef, useEffect, useState } from 'react';

// import maplibregl from 'maplibre-gl';
// import 'maplibre-gl/dist/maplibre-gl.css';

import '@maptiler/sdk/dist/maptiler-sdk.css'
import * as mapsdk from '@maptiler/sdk'

import classes from './Map.module.css';
import {useSelector, useDispatch} from 'react-redux'
import {setLngLatAction, setCurrentEventContextAction} from 'store/slice/events'
import {setEventViewStateAction} from 'store/slice/states'
import {setLngAction, setLatAction, setZoomAction} from 'store/slice/settings'
import {listEvents} from 'store/thunks/events'
import {generateGeoJSON, generateGeoJSONData} from 'utils/event_list_utils'
import {MapLayers} from './map_layers'


export default function Map() {
	const mapContainer = useRef(null);
	const eventDraftMarker = useRef(null);
	const map = useRef(null);
	const rerenderingMarks = useRef(false);
	// const img = useRef(null);
	// const [lng, setLng] = useState(51.505);
	// const [lat, setLat] = useState(-0.09);
	// const [zoom] = useState(0);
	const lng = useSelector(state => state.settings.map_context_lng)
	const lat = useSelector(state => state.settings.map_context_lat)
	const zoom = useSelector(state => state.settings.map_context_zoom)

	const settingMarker = useSelector(state => state.states.state === 'event_edit_marker')
	const draft = useSelector(state => state.events.event_draft)
	const eventList = useSelector(state => state.events.event_list)
	const currEventContext = useSelector(state => state.events.curr_event_context)
	const reloadEventList = useSelector(state => state.events.reload_event_list)
	const currState = useSelector(state => state.states.state)
	const currStateContext = useSelector(state => state.states.state_context)
	const dispatch = useDispatch()

	const handleReloadEventList = (center, bounds) => {
		let lng_area = Math.min(0.4000, Math.abs(bounds.getEast() - bounds.getWest()))
		let lat_area = Math.min(0.2000, Math.abs(bounds.getNorth() - bounds.getSouth()))
		const geofilter = {
			'geofilter': {
				lat_from: Math.max(center.lat-(lat_area*0.5), -90.0).toFixed(4),
				lat_to: Math.min(center.lat+(lat_area*0.5), 90.0).toFixed(4),
				lng_from: Math.max(center.lng-(lng_area*0.5), -180.0).toFixed(4),
				lng_to: Math.min(center.lng+(lng_area*0.5), 180.0).toFixed(4)
			}
		}
		dispatch(listEvents(geofilter))
	}

	const rerenderMarks = (callback=false) => {
			if (rerenderingMarks.current && !callback) return;

			if (!map.current.isStyleLoaded()) {
				if (!rerenderingMarks.current || callback) {
					rerenderingMarks.current = true
					setTimeout(() => {rerenderMarks(true)}, 200)
				}
				return
			} else {
				rerenderingMarks.current = false
				const mapLayers = new MapLayers(eventList)

				const event_types = ['couple', 'small_group', 'big_group', 'mass_event']
				const event_status = ['standard', 'organized', 'participating', 'invited', 'cancelled']

				for (let etype of event_types) {
					for (let estat of event_status) {
						let name = 'event-markers-' + etype + '-' + estat
						if (!map.current.getSource(name)) {
							map.current.addSource(name, mapLayers.getGeoJSON(mapLayers.getLayerGeoData(etype, estat)))
						} else {
							map.current.getSource(name).setData(mapLayers.getLayerGeoData(etype, estat))
						}

						if (!map.current.getLayer(name)) {
							let icon_img = etype + '_'
							let icon_size = 1
							if (estat === 'cancelled') 			icon_img += 'cancelled'
							else if (estat === 'invited') 		icon_img += 'invited'
							else if (estat === 'participating') icon_img += 'apply'
							else if (estat === 'organized') 	icon_img += 'organized'
							else 								icon_img += 'primary'
							map.current.addLayer({
								'id': name,
								'type': 'symbol',
								'source': name,
								'layout': {
									'icon-image': icon_img,
									'icon-size': icon_size
								}
							})
							map.current.on('click', name, (e) => {
								dispatch(setEventViewStateAction({'id': e.features[0].properties.event_id}))
							})
							map.current.on('mouseenter', name, () => {
								map.current.getCanvas().style.cursor = 'pointer'
							})
							map.current.on('mouseleave', name, () => {
								map.current.getCanvas().style.cursor = ''
							})
						}
					}
				}
			}
		}

	useEffect(() => {
		if (reloadEventList) {
			if (!map.current || !reloadEventList) return;

			const center = map.current.getCenter()
			const bounds = map.current.getBounds()
			handleReloadEventList(center, bounds)
		}
	}, [reloadEventList])

	useEffect(() => {
		if (!map.current || !currEventContext || currState !== 'event_view' || !currStateContext.fly_to_location) return
		const lngLat = [parseFloat(currEventContext.lng), parseFloat(currEventContext.lat)]
		map.current.flyTo({
			center: lngLat,
			zoom: 14,
			essential: true
		})
	}, [currEventContext, currState])

	useEffect(() => {
		if (map.current) return;

		let mLngLat = [lng, lat]
		if (draft && draft.lng && draft.lng)
		{
			mLngLat = [draft.lng, draft.lat]
		}

		mapsdk.config.apiKey = 'CdFVnEDfFAXjAD1QeZk2'
		mapsdk.config.caching = false // TODO: remove on prod(https only support) !!!!
		mapsdk.config.primaryLanguage = mapsdk.Language.AUTO
		map.current = new mapsdk.Map({
			// style: {
			// 	'version': 8,
			// 	'sources': {
			// 		'raster-tiles': {
			// 			'type': 'raster',
			// 			'tiles': [
			// 				'https://api.maptiler.com/maps/bright-v2/{z}/{x}/{y}.png?key=CdFVnEDfFAXjAD1QeZk2'
			// 			],
			// 			'tileSize': 256
			// 		}
			// 	},
			// 	'layers': [
			// 		{
			// 			'id': 'simple-tiles',
			// 			'type': 'raster',
			// 			'source': 'raster-tiles',
			// 			'minzoom': 0,
			// 			'maxzoom': 22
			// 		}
			// 	]
			// },
			style: mapsdk.MapStyle.STREETS,
			navigationControl: 'top-left',
			geolocateControl: 'top-left',
			container: mapContainer.current,
			center: mLngLat,
			zoom: zoom
		});

		map.current.on('load', async () => {
			const ev_types = ['couple', 'small_group', 'big_group', 'mass_event']
			const img_types = ['primary', 'secondary', 'cancelled', 'apply', 'invited', 'organized']

			for (let ev of ev_types) {
				for (let im of img_types) {
					let curr_img = await map.current.loadImage(process.env.PUBLIC_URL + '/' + ev + '_' + im + '.png')
					map.current.addImage(ev + '_' + im, curr_img.data)
				}
			}

			// map.current.addControl(new maplibregl.LogoControl({compact: true}))
		})

		map.current.on('moveend', (e) => {
			const center = e.target.getCenter()
			dispatch(setLngAction(center.lng))
			dispatch(setLatAction(center.lat))
			dispatch(setZoomAction(Math.floor(e.target.getZoom())))
			const bounds = e.target.getBounds()
			handleReloadEventList(center, bounds)
		})

	}, []);

	useEffect(() => {
		if (!map.current) return;

		rerenderMarks()

	}, [eventList])

	useEffect(() => {
		if (!map.current) return;

		if (settingMarker && !eventDraftMarker.current) {
			const curr_center = map.current.getCenter()
			let mLngLat = [curr_center.lng, curr_center.lat]
			if (draft && draft.lng && draft.lat)
			{
				mLngLat = [draft.lng, draft.lat]
			}
			eventDraftMarker.current = new mapsdk.Marker({color: "#FF0000", draggable: true})
				.setLngLat(mLngLat)
				.addTo(map.current)

			function onDragEnd(e) {
				const {lng, lat} = e.target.getLngLat()
				dispatch(setLngLatAction({lng: lng.toFixed(4), lat: lat.toFixed(4)}))
			}
			eventDraftMarker.current.on('dragend', onDragEnd)
		} else if (!settingMarker && eventDraftMarker.current) {
			eventDraftMarker.current.remove()
			eventDraftMarker.current = null
		}

	}, [settingMarker])

	return (
		<div className={classes.map_wrap}>
			<div ref={mapContainer} className={classes.map} />
		</div>
	)
}