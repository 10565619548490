import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {useEffect} from 'react'
import {setLocaleRUAction, setLocaleENAction} from 'store/slice/settings'

import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'


export default function LocalePannel() {
	const locale = useSelector(state => state.settings.locale)
	const dispatch = useDispatch()
	const {t, i18n} = useTranslation()

	useEffect(() => {
		if (locale === 'ru') i18n.changeLanguage("ru")
		else 				 i18n.changeLanguage("en")
	}, [locale])

	return (
		<Box
			sx={{
				position: 'absolute',
				right: '20px',
				bottom: '25px'
			}}
		>
			<Stack direction="row" spacing={1}>
				<Chip
					avatar={<Avatar src={process.env.PUBLIC_URL + '/ru_icon.svg'}></Avatar>}
					label="RU"
					size="small"
					onClick={() => {
						dispatch(setLocaleRUAction())
					}}
				/>
				<Chip
					avatar={<Avatar src={process.env.PUBLIC_URL + '/en_icon.svg'}></Avatar>}
					label="EN"
					size="small"
					onClick={() => {
						dispatch(setLocaleENAction())
					}}
				/>
			</Stack>
		</Box>
	)
}