import classes from './FriendList.module.css'
import {useState, forwardRef, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import service from "utils/axios"
import {useTranslation} from 'react-i18next'
import {notifyAction} from 'store/slice/notify'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import FolderIcon from '@mui/icons-material/Folder'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListSubheader from '@mui/material/ListSubheader'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import {deepOrange} from '@mui/material/colors'


const FriendList = forwardRef((props, ref) => {
	const [checked, setChecked] = useState([])
	const friendList = useSelector(state => state.auth.user.friend_list)
	const inviteList = useSelector(state => state.auth.user.friend_invite_list)
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const {
		userContextHandler,
		hideInvites,
		checkList,
		eventContextID,
		onClose,
	...restProps} = props

	const sections = []
	if (!hideInvites) {
		sections.push({'section': t('invites'), 'users': inviteList ? inviteList : []})
	}
	sections.push({'section': t('friends'), 'users': friendList ? friendList : []})

	const handleToggle = (value) => () => {
		const currIndex = checked.indexOf(value)
		const newChecked = [...checked]
		if (currIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currIndex, 1)
		}
		setChecked(newChecked)
	}

	const handleSendInvites = async () => {
		const flist = []
		for (let i=0; i < checked.length; ++i) {
			flist.push(friendList[checked[i]].user_id)
		}
		if (flist.length > 0) {
			// TODO: toast flist limit overflow
			service.post("/event/invite/send", {
				'event_id': eventContextID,
				'invite_list': flist.slice(0, 30)
			})
			.then((res) => {
				// TODO: success toast
			})
			.catch((err) => {
				dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
			})
		}
		onClose()
	}

	let checkAction = (value) => null
	if (checkList) {
		checkAction = (value) => {
			return (
				<Checkbox
					edge="end"
					onChange={handleToggle(value)}
					checked={checked.indexOf(value) !== -1}
					inputProps={{'aria-labelledby': `checkbox-${value}`}}
				/>
		)}
	}

	return (
		<Box className={classes['friend-list-box']} {...restProps} ref={ref}
			sx={{
				maxWidth: 360,
				bgcolor: 'white',
				bottom: '200px',
				pb: 1
			}}
		>
			<List
				dense
				sx={{
					m: 0,
					p: 0,
					width: '100%',
					left: 0,
					height: '100%',
					overflow: 'auto'
				}}
			>
				
				{sections.map(({section, users}) => (
					<li key={section}>
						<ul>
							<ListSubheader>{section}</ListSubheader>
							{users.map((item, index) => (
								<ListItem
									key={(item.initiator_name ? item.initiator_name : item.user_name) + String(index)}
									secondaryAction={checkAction(index)}
								>
									<ListItemButton
										onClick={checkList ? null : ()=>{userContextHandler(item)}}
									>
										<ListItemAvatar>
											<Avatar sx={{bgcolor: '#3f9fdf'}}>{item.initiator_name ? item.initiator_name.substring(0,1) : item.user_name.substring(0,1)}</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={item.initiator_name ? item.initiator_name : item.user_name}
										/>
									</ListItemButton>
								</ListItem>
							))}
						</ul>
					</li>
				))}

			</List>

			{checked.length > 0 &&
				<Stack
					direction="row"
					spacing={1}
					justifyContent="center"
					alignItems="center"
					sx={{mb:2,mt:2}}
				>
					<Button variant="contained" startIcon={<SendIcon />} onClick={handleSendInvites}>
						{t('send')}
					</Button>
					<Button variant="outlined" sx={{bgcolor: 'white'}} startIcon={<DeleteIcon />} onClick={() => {
						setChecked([])
					}}>
						{t('cancel')}
					</Button>
				</Stack>
			}
		</Box>
	)
})

export default FriendList