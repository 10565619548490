import {createSlice, createAction} from "@reduxjs/toolkit"
import {ackEvent, cancelEvent, fetchEventByID, publishEvent, listEvents, fetchMyIntents, createIntent} from "store/thunks/events"
import {getMe, updateMe, fetchFriendList} from 'store/thunks/user'
import {regUser, loginUser} from 'store/thunks/auth'

const initialState = {
	notification: null,
	severity: 'success',
	duration: 3000
}

export const notifyAction = createAction('notify')
const notifyReducer = (state, action) => {
	state.notification = action.payload.notification
	state.severity = action.payload.severity ? action.payload.severity : 'success'
	state.duration = action.payload.duration ? action.payload.duration : 3000
}

export const dropNotifyAction = createAction('drop_notify')
const dropNotifyReducer = (state, action) => {
	state.notification = null
	state.duration = 3000
}

const notifyNetworkError = (state) => {
	state.notification = 'network_error_notify'
	state.severity = 'error'
}

const notifyUnauthorizedError = (state) => {
	state.notification = 'unauthorized_error_notify'
	state.severity = 'info'
}

const notifyLoginUnauthError = (state) => {
	state.notification = 'login_unauthorized_error_notify'
	state.severity = 'error'
}

const notifyUserAlreadyExists = (state) => {
	state.notification = 'user_already_exists_notify'
	state.severity = 'error'
}

const notifyInvalidEmailOrPass = (state) => {
	state.notification = 'invalid_user_email_or_pass_notify'
	state.severity = 'error'
}

export const notifySlice = createSlice({
	name: 'notify',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			notifyAction,
			notifyReducer
		)
		builder.addCase(
			dropNotifyAction,
			dropNotifyReducer
		)

		builder.addCase(
			updateMe.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			listEvents.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			fetchMyIntents.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			createIntent.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			fetchEventByID.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			cancelEvent.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			ackEvent.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			fetchFriendList.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			regUser.rejected,
			(state, action) => {
				if (action.payload === 401) notifyUnauthorizedError(state)
				else if (action.payload === 409) notifyUserAlreadyExists(state)
				else notifyNetworkError(state)
			}
		)
		builder.addCase(
			loginUser.rejected,
			(state, action) => {
				if (action.payload === 401 || action.payload === 422) notifyInvalidEmailOrPass(state)
				else notifyNetworkError(state)
			}
		)
	}
})

export default notifySlice.reducer