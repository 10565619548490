import { useState } from 'react'
import classes from './MainMenuButton.module.css'

import Fab from '@mui/material/Fab'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'


export default function MainMenuButton({onClick}) {
	return (
		<Fab
			onClick={onClick}
			size="medium"
			// color="info"
			sx={{
				position: 'absolute',
				top: 16,
				right: 16,
				bgcolor: 'white',
				color: '#5e5e5e'
			}}
		>
			<AccountCircleOutlinedIcon />
		</Fab>
	)
}