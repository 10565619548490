import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {styled} from '@mui/material/styles'
import dayjs from 'dayjs'
import service from "utils/axios"
import {useTranslation} from 'react-i18next'
import {notifyAction} from 'store/slice/notify'

import {fetchEventByID, publishEvent} from 'store/thunks/events'
import {
	initEventDraftAction,
	editEventAction,
	updateEventDraftAction,
	dropEventDraftAction,
	publishEventAction
} from 'store/slice/events'
import {setRootStateAction, setEventEditMarkerStateAction} from 'store/slice/states'
import EventTags from './EventTags/EventTags'

import LoadingButton from '@mui/lab/LoadingButton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'


const FileInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
})

function EventType() {
	const event_draft = useSelector(state => state.events.event_draft)
	const dispatch = useDispatch()
	const {t} = useTranslation()

	return (
		<FormControl variant="standard" sx={{minWidth: 120}} size="small">
			<Select required value={event_draft.event_type} onChange={(e) => {
				dispatch(updateEventDraftAction({event_type: e.target.value}))
			}}>
				<MenuItem value='couple'>{t('couple')}</MenuItem>
				<MenuItem value='small_group'>{t('small_group')}</MenuItem>
				<MenuItem value='big_group'>{t('big_group')}</MenuItem>
				<MenuItem value='mass_event'>{t('mass_event')}</MenuItem>
			</Select>
		</FormControl>
	)
}

function VisibilityOptions() {
	const event_draft = useSelector(state => state.events.event_draft)
	const dispatch = useDispatch()
	const {t} = useTranslation()

	return (
		<FormControl variant="standard" sx={{minWidth: 120}} size="small">
			<Select defaultValue="all" value={event_draft.visible_to} onChange={(e) => {
				dispatch(updateEventDraftAction({visible_to: e.target.value}))
			}}>
				<MenuItem value='all'>{t('all')}</MenuItem>
				<MenuItem value='participants'>{t('participants')}</MenuItem>
			</Select>
		</FormControl>
	)
}

function EditRow({children, color}) {
	const style = {
		bgcolor: 'white',
		borderRadius: 2,
		// border: 'solid 1px #cdcdcd',
		my: 1,
		mx: 2,
		py: 1,
		px: 2
	}
	if (color) style.border = 'solid 1px ' + color
	return (
		<Grid
			container
			item
			sx={style}
			spacing={0}
			columnSpacing={1}
			rowSpacing={0}
		>
			{children}
		</Grid>
	)
}

export default function EventEditContext({eventID}) {
	const [pendingPublish, setPendingPublish] = useState(false)
	const eventContext = useSelector(state => state.events.curr_event_context)
	const eventDraft = useSelector(state => state.events.event_draft)
	// const pendingPublish = useSelector(state => state.events.pending_publish)
	const dispatch = useDispatch()
	const {t} = useTranslation()

	useEffect(() => {
		if (eventID)
			dispatch(fetchEventByID(eventID))
		else if (!eventDraft)
			dispatch(initEventDraftAction())
	}, [])

	useEffect(() => {
		if (eventContext)
			dispatch(editEventAction(eventContext))
	}, [eventContext])

	if (!eventDraft) {
		return (
			<Box sx={{width: '100%'}}>
				<LoadingButton fullWidth size="large" disabled loading={true}>Loading</LoadingButton>
			</Box>
		)
	}

	const date = dayjs(eventDraft.event_date)
	const tformat = 'HH:mm A'
	const time = dayjs(eventDraft.event_time, tformat)

	const handlePublish = async (draft) => {
		setPendingPublish(true)
		await service.post('event/publish', draft)
		.then((res) => {
			dispatch(publishEventAction(res.data))
			dispatch(setRootStateAction())
			dispatch(dropEventDraftAction())
			setPendingPublish(false)
		})
		.catch((err) => {
			setPendingPublish(false)
			// TODO: process specific errors
			if (err.response && err.response.status) {
				if (err.response.status === 406) {
					dispatch(notifyAction({'notification': 'event_update_forbidden', 'severity': 'error'}))
				} else if (err.response.status === 422) {
					dispatch(notifyAction({'notification': 'check_event_form_notify', 'severity': 'warning'}))
				} else if (err.response.status === 403) {
					dispatch(notifyAction({'notification': 'event_create_forbidden', 'severity': 'error'}))
				} else {
					dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
				}
			}
		})
		// dispatch(publishEvent(eventDraft))
	}

	return (
		<Container
			component="main"
			sx={{
				mb: 2,
				mt: 0
			}}
			disableGutters
		>
			<CssBaseline />

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					borderRadius: 2,
					width: '100%',
				}}
			>
				<Grid
					container
					rowSpacing={0}
					columnSpacing={0.4}
					alignItems="center"
					justifyContent="flex-start"
					sx={{mt:1}}
				>
					<Grid item xs={3}></Grid>
					<Grid item xs={2}>
						<Avatar
							sx={{m: 1}}
						>
							<PlaceRoundedIcon />
						</Avatar>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="h5">{t('new_event')}</Typography>
						{/*<Button
							component="label"
							role={undefined}
							variant="contained"
							tabIndex={-1}
							startIcon={<CloudUploadRoundedIcon fontSize="small" />}
							size="small"
						>
							change
							<FileInput type="file" />
						</Button>*/}
					</Grid>
					{/*<Grid item xs={4}></Grid>*/}

					<EditRow>
						<Grid item xs={4}>
							<Typography variant="h6">{t('title')}:</Typography>
						</Grid>
						<Grid item xs={8}>
							<TextField
								fullWidth
								value={eventDraft.title}
								variant="standard"
								inputProps={{
									maxLength: 50
								}}
								onChange={(e) => {
									dispatch(updateEventDraftAction({title: e.target.value}))
								}}
							/>
						</Grid>
					</EditRow>

					<EditRow>
						<Grid item xs={5}>
							<Typography sx={{'&::first-letter': {textTransform: 'capitalize'}}} variant="h6">{t('event_type')}:</Typography>
						</Grid>
						<Grid item xs={7}>
							<EventType />
						</Grid>
					</EditRow>

					<EditRow>
						<Grid item xs={4}>
							<Typography sx={{'&::first-letter': {textTransform: 'capitalize'}}} variant="h6">{t('visible_to')}:</Typography>
						</Grid>
						<Grid item xs={8}>
							<VisibilityOptions />
						</Grid>
					</EditRow>

					<EditRow>
						<Grid item xs={12}>
							<EventTags />
						</Grid>
					</EditRow>

					<EditRow color={eventDraft.lat && eventDraft.lng ? 'green' : 'red'}>
						<Grid item xs={4}>
							<Typography sx={{'&::first-letter': {textTransform: 'capitalize'}}} variant="h6">{t('location')}:</Typography>
						</Grid>
						<Grid item xs={8}>
							<Button fullWidth variant="contained" color="primary" onClick={() => {
								dispatch(setEventEditMarkerStateAction())
							}}>
								{t('set_location')}
							</Button>
						</Grid>
					</EditRow>

					<EditRow>
						<Grid item xs={6}>
							<DatePicker value={date} onChange={(e) => {
								dispatch(updateEventDraftAction({event_date: dayjs(e).format('YYYY-MM-DD')}))
							}} />
						</Grid>
						<Grid item xs={6}>
							<TimePicker value={time} onChange={(e) => {
								dispatch(updateEventDraftAction({event_time: dayjs(e).format(tformat)}))
							}}/>
						</Grid>
					</EditRow>

					<EditRow>
						<Grid item xs={12}>
							<Typography variant="h6">{t('description')}:</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								type="text"
								variant="outlined"
								multiline
								rows={5}
								value={eventDraft.desc}
								inputProps={{
									maxLength: 200
								}}
								helperText={`${eventDraft.desc ? eventDraft.desc.length : 0}/${200}`}
								onChange={(e) => {
									dispatch(updateEventDraftAction({desc: e.target.value}))
								}}
							/>
						</Grid>
					</EditRow>

					<Grid item xs={12}>
						<Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
							<LoadingButton
								loading={pendingPublish}
								variant="contained"
								color="primary"
								onClick={async () => {await handlePublish(eventDraft)}}
								startIcon={<SendRoundedIcon />}
							>
								{t('publish')}
							</LoadingButton>

							{eventDraft.related_event &&
								<Button
									variant="outlined"
									color="primary"
									onClick={() => {
										dispatch(setRootStateAction())
										dispatch(dropEventDraftAction())
									}}
									startIcon={<DeleteForeverRoundedIcon />}
								>
									{t('cancel')}
								</Button>
							}

							{!eventDraft.related_event && 
								<Button
									variant="outlined"
									color="primary"
									onClick={() => {
										dispatch(initEventDraftAction())
									}}
									startIcon={<DeleteForeverRoundedIcon />}
								>
									{t('clear')}
								</Button>
							}
						</Stack>
					</Grid>

				</Grid>

			</Box>

		</Container>
	)
}