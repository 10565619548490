import classes from './EventTags.module.css'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {useTranslation} from 'react-i18next'


import {useDispatch, useSelector} from 'react-redux'
import {updateEventDraftAction} from 'store/slice/events'


export default function EventTags() {
	const event_draft = useSelector(state => state.events.event_draft)
	const dispatch = useDispatch()
	const {t} = useTranslation()
	let currVals = []
	if (event_draft.tags) {
		for (let tag of eventTags) {
			for (let savedtag of event_draft.tags) {
				if (tag.val === savedtag) {
					currVals.push(tag)
					break
				}
			}
		}
	}

	return (
		<Autocomplete
			multiple
			limitTags={2}
			value={currVals}
			options={eventTags}
			getOptionLabel={(option) => t(option.val)}
			renderInput={(props) => {
				// TODO: WARNING(passing key prop into JSX) (fixed in v6.0.0-alpha.7 #42099) just wait release
				return <TextField {...props} placeholder={t('tags')} variant="standard" />
			}}
			// sx = {{width: '350px'}}
			onChange={(e, value) => {
				if (value) {
					dispatch(updateEventDraftAction({tags: value.map(v => v.val)}))
				}
			}}
		/>
	)
}

// TODO: remove after fix WARNING(#42099)
// const oldWarn = console.error
// const SUPRESSED_WARNS = ['A props object containing a "key" prop is being spread into JSX']
// console.error = function filterWarn(msg, ...args) {
// 	if (!SUPRESSED_WARNS.some((entry) => msg.includes(entry))) {
// 		oldWarn(msg, ...args)
// 	}
// }

const eventTags = [
	{val: 'city_center'},
	{val: 'countryside'},
	{val: 'walk'},
	{val: 'on_transport'},
	{val: 'two_wheels'},
	{val: 'four_wheels'},
	{val: 'leisure'},
	{val: 'loft'},
	{val: 'watch'},
	{val: 'conversation'},
	{val: 'game'},
	{val: 'trip'},
	{val: 'culture'},
	{val: 'concert'},
	{val: 'nature'},
	{val: 'tourism'},
	{val: 'acquaintance'},
	{val: 'party'},
	{val: 'music'},
	{val: 'meals'},
	{val: 'sport'},
	{val: 'clear_weather'},
	{val: 'any_weather'},
	{val: 'early_motning'},
	{val: 'late_evening'},
]