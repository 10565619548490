const locResource = {
	resources: {
		en: {
			translation: {
				contacts: "Contacts",
				complaints: "Complaints",
				cooperation: "Cooperation",
				support_email: "Support Email",
				service_rules: "Service rules",
				service_rules_text0: "- Advertising content is prohibited",
				service_rules_text1: "- Insults and profanity are prohibited",
				service_rules_text2: "- Forbidden all publications that violate the law",
				signin: "Sign In",
				signup: "Sign Up",
				email_address: "Email Address",
				password: "Password",
				forgot_password: "Forgot password?",
				rec_pass_title: "Password recovery",
				rec_pass_desc: "Enter your email please. We will send you new password",
				rec_pass_button: "Reset password",
				remember_pass: "Remember your password? Sign in",
				name: "Name",
				rep_pass: "Repeat password",
				already_have_account: "Already have account? Sing in",
				profile_settings: "Profile/Settings",
				you_are_invited:"You are invited",
				events: "Events",
				invites: "Invites",
				friend: "Friend",
				friends: "Friends",
				remove_friend: "Remove friend",
				accept_friend: "Accept req.",
				decline_friend: "Decline req.",
				rep_block: "Report/Block",
				rep_violation: "Report violation",
				unblock: "Unblock",
				support: "Support",
				logout: "Logout",
				change: "change",
				back: "Back",
				accept: "Accept",
				cancel: "Cancel",
				new_email: "New Email",
				rep_email: "Repeat Email",
				curr_pass: "Current password",
				old_pass: "Old password",
				new_pass: "New password",
				submit: "Submit",
				about_user: "About",
				public_profile: "Public profile:",
				cancel_event_approve_title: "Cancel Event",
				are_you_sure: "Are you sure?",
				b_d: "B.D.:",
				gender: "Gender:",
				male: "Male",
				female: "Female",
				unknown: "Unknown",
				change_pass: "Change password",
				organizer: "Organizer",
				participate: "Participate",
				cancelled: "cancelled",
				confirmed: "confirmed",
				couple: "company",
				small_group: "group",
				big_group: "big group",
				mass_event: "mass event",
				peop: "p.",
				y_: "y.",
				m_: "m.",
				d_: "d.",
				h_: "h.",
				min_: "min.",
				intended: "will go",
				event_type: "event type",
				remain: "remain",
				date: "Date",
				location: "Location",
				set_location: "Set location",
				description: "Description",
				publish: "Publish",
				clear: "Clear",
				show_on_map: "show on map",
				invite: "Invite",
				edit: "Edit",
				cancel_event: "Cancel Event",
				ack_event: "Approve",
				report: "Report",
				hide: "Скрыть",
				refuse: "Won't go",
				intent: "Will go",
				decline: "Decline",
				send: "Send",
				friend_chip: "friend",
				block_chip: "blocked",
				sub_chip: "subscribed",
				new_event: "New Event",
				title: "Title",
				all: "all",
				age: "age",
				participants: "participants",
				visible_to: "visible to",
				event_visible_to: "Event visible to",
				tags: "Tags",
				started: "already started",
				accept_position: "Accept location",
				event_link: "Event link",
				copy_event_link: "copy event link",
				share_link: "share",

				event_link_copied_notify: "Event link copied to clipboard",
				event_report_notify: "Event reported",
				network_error_notify: "Something went wrong, try again later",
				invite_accepted_notify: "Invite accepted",
				invite_declined_notify: "Invite declined",
				unauthorized_error_notify: "Authorization failed, please SignIn!",
				login_unauthorized_error_notify: "Authorization failed, invalid email or password!",
				event_update_forbidden: "Event date or time update forbidden when there is a day or less left!",
				event_create_forbidden: "Forbidden, possible too many events created, try again later",
				passwords_not_equal_notify: "Password not equal",
				password_invalid_format_notify: "Invalid password format. It should be 6-20 characters long, should contain at least one numeral, lowercase and uppercase, and also should not contain any spaces.",
				user_already_exists_notify: "User with this email already exists",
				invalid_user_email_or_pass_notify: "Invalid user email or password!",
				input_email_notify: "Enter email",
				email_not_equal_notify: "Emails not equal",
				email_occupied_notify: "Email is already taken",
				invalid_password_notify: "Invalid password",
				invalid_email_format: "Invalid email",
				input_password_notify: "Enter password",
				check_event_form_notify: "Incorrect form, please check required fields input",

				city_center: 'city center',
				countryside: 'countryside',
				walk: 'walk',
				on_transport: 'on transport',
				two_wheels: 'two wheels',
				four_wheels: 'four wheels',
				leisure: 'leisure',
				loft: 'loft',
				watch: 'watch',
				conversation: 'conversation',
				game: 'game',
				trip: 'trip',
				culture: 'culture',
				concert: 'concert',
				nature: 'nature',
				tourism: 'tourism',
				acquaintance: 'acquaintance',
				party: 'party',
				music: 'music',
				meals: 'meals',
				sport: 'sport',
				clear_weather: 'clear weather',
				any_weather: 'any weather',
				early_motning: 'early motning',
				late_evening: 'late evening',
			}
		},
		ru: {
			translation: {
				complaints: "Жалобы",
				cooperation: "Сортудничество",
				contacts: "Контакты",
				support_email: "Email поддержки",
				service_rules: "Правила сервиса",
				service_rules_text0: "- Запрещено публиковать рекламный контент",
				service_rules_text1: "- Запрещены оскорбления и ненормативная лексика",
				service_rules_text2: "- Запрещены любые публикации нарушающие закон",
				signin: "Войти",
				signup: "Регистрация",
				email_address: "Ваш Email",
				password: "Пароль",
				forgot_password: "Забыли пароль?",
				rec_pass_title: "Восстановление пароля",
				rec_pass_desc: "Забыли пароль? Пожалуйста, введите свой email адрес, мы отправим ваш пароль на этот email.",
				rec_pass_button: "Сбросить пароль",
				remember_pass: "Вспомнили пароль? Войти",
				name: "Имя",
				rep_pass: "Повторите пароль",
				already_have_account: "Уже есть аккуант? Войти",
				profile_settings: "Профиль/Настройки",
				you_are_invited: "Вы приглашены",
				events: "События",
				invites: "Приглашения",
				friend: "Друг",
				friends: "Друзья",
				remove_friend: "Удл. из друзей",
				accept_friend: "Принять запрос",
				decline_friend: "Отклонить запрос",
				rep_block: "Жалоба/ЧС",
				rep_violation: "Сообщить о нарушении",
				unblock: "Убрать из ЧС",
				support: "Поддержка",
				logout: "Выход",
				change: "изменить",
				back: "Назад",
				accept: "Принять",
				cancel: "Отмена",
				new_email: "Новый Email",
				rep_email: "Повторите Email",
				curr_pass: "Текущий пароль",
				old_pass: "Старый пароль",
				new_pass: "Новый пароль",
				submit: "Подтвердить",
				about_user: "Обо мне",
				public_profile: "Профиль открыт:",
				cancel_event_approve_title: "Отменить Событие",
				are_you_sure: "Вы уверенны?",
				b_d: "Д.Р.:",
				gender: "Пол:",
				male: "Мужчина",
				female: "Женщина",
				unknown: "Не известно",
				change_pass: "Сменить пароль",
				organizer: "Организатор",
				participate: "Участник",
				cancelled: "отменен",
				confirmed: "подтвержден",
				couple: "компания",
				small_group: "группа",
				big_group: "большая группа",
				mass_event: "массовое мероприятие",
				peop: "чел.",
				y_: "л.",
				m_: "мес.",
				d_: "дн.",
				h_: "час.",
				min_: "мин.",
				intended: "пойдут",
				event_type: "тип события",
				remain: "до события",
				date: "Дата",
				location: "Место",
				set_location: "Установить место",
				description: "Описание",
				publish: "Опубликовать",
				clear: "Очистить",
				show_on_map: "показать на карте",
				invite: "Пригл.",
				edit: "Ред.",
				cancel_event: "Отм. событие",
				ack_event: "Утвердить",
				report: "Пожалов.",
				hide: "Скрыть",
				refuse: "Не пойду",
				intent: "Пойду",
				decline: "Отклонить",
				send: "Отпр.",
				friend_chip: "друг",
				block_chip: "в чс",
				sub_chip: "подписан",
				new_event: "Новое событие",
				title: "Название",
				all: "все",
				age: "лет",
				participants: "участники",
				visible_to: "видят",
				event_visible_to: "Событие видят",
				tags: "Теги",
				started: "уже началось",
				accept_position: "Подтвердить место",
				event_link: "Ссылка на событие",
				copy_event_link: "скопировать ссылку",
				share_link: "поделиться",

				event_link_copied_notify: "Ссылка скопирована",
				event_report_notify: "Жалоба отправлена",
				network_error_notify: "Что-то пошло не так, попробуйте позднее",
				invite_accepted_notify: "Приглашение принято",
				invite_declined_notify: "Приглашение отклонено",
				unauthorized_error_notify: "Ошибка авторизации, пожалйста войдите в свой аккаунт!",
				login_unauthorized_error_notify: "Ошибка авторизации, неверный адрес или пароль!",
				event_update_forbidden: "Меньше чем за день у события нельзя менять дату и время!",
				event_create_forbidden: "Временно запрещено создавать события, попробуйте позже",
				passwords_not_equal_notify: "Пароли не совпадают",
				password_invalid_format_notify: "Неправильный формат пароля. Он должен состоять из 6-20 символов, минимум одной цифры, буквы в малом и большом регистре и не должен содержать пробелов.",
				user_already_exists_notify: "Пользователь с таким email уже существует",
				invalid_user_email_or_pass_notify: "Неверный email или пароль!",
				input_email_notify: "Введите email",
				email_not_equal_notify: "Email не совпадает",
				email_occupied_notify: "Данный email уже занят",
				invalid_password_notify: "Введен неверный пароль",
				invalid_email_format: "Введен неправильный email",
				input_password_notify: "Введите пароль",
				check_event_form_notify: "Некорректно заполнена форма, пожалуйста, проверьте заполненные поля",

				city_center: 'центр города',
				countryside: 'за городом',
				walk: 'прогулка',
				on_transport: 'на транспорте',
				two_wheels: 'два колеса',
				four_wheels: 'четыре колеса',
				leisure: 'активный отдых',
				loft: 'заведение',
				watch: 'просмотр',
				conversation: 'общение',
				game: 'игра',
				trip: 'выезд',
				culture: 'культура',
				concert: 'концерт',
				nature: 'природа',
				tourism: 'туризм',
				acquaintance: 'знакомство',
				party: 'вечеринка',
				music: 'музыка',
				meals: 'еда',
				sport: 'спорт',
				clear_weather: 'ясная погода',
				any_weather: 'любая погода',
				early_motning: 'раннее утро',
				late_evening: 'поздний вечер',
			}
		}
	},
	lng: 'ru',
	fallbackLng: 'ru'
}

export default locResource;