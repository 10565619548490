import classes from './NewEventButton.module.css'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Zoom from '@mui/material/Zoom'
import {useTranslation} from 'react-i18next'

import {useDispatch, useSelector} from 'react-redux'
import {settingEventDataAction} from 'store/slice/events'
import {setEventEditStateAction} from 'store/slice/states'


export default function NewEventButton() {
	const dispatch = useDispatch()
	const setMarkerMode = useSelector(state => state.events.setting_marker)
	const setEventDataMode = useSelector(state => state.events.setting_event_data)
	const currState = useSelector(state => state.states.state)
	const authorized = useSelector(state=> state.auth.authorized)
	const {t} = useTranslation()
	const visible = !setMarkerMode &&
					currState == 'root' &&
					authorized

	if (visible)
		return (
			<div className={classes.button}>
				<Zoom in={visible} addEndListener={(b) => {
					// process on zoom out
				}}>
					<Fab
						size="medium"
						variant="extended"
						// color="info"
						onClick={() => {
							dispatch(setEventEditStateAction())
						}}
						sx={{
							borderRadius: 4,
							bgcolor: '#74c333',//'#74c333', '#81d340'
							color: 'white'
						}}
					>
						<AddIcon sx={{mr:1}} />
						{t('new_event')}
					</Fab>
				</Zoom>
			</div>
		)
	else return <div></div>
}