import service from "utils/axios"
import {useDispatch, useSelector} from 'react-redux'
import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {notifyAction} from 'store/slice/notify'
import dayjs from 'dayjs'
// import {
// 	sendFriendRequest
// } from 'store/thunks/user'

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import ReportRoundedIcon from '@mui/icons-material/ReportRounded'
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded'
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded'
import DoNotDisturbRoundedIcon from '@mui/icons-material/DoNotDisturbRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'


function ReportModal({userContext, onSubmit, onClose}) {
	const [rep, setRep] = useState('')
	const {t} = useTranslation()

	const handleSubmit = async (e) => {
		e.preventDefault()
		await onSubmit(userContext.id, rep)
		onClose()
	}

	return (
		<Modal
			open={!!userContext}
			onClose={onClose}
		>
			<Box
				sx={{
					maxWidth: 400,
					bgcolor: '#fafafa',
					position: 'absolute',
					top: '30%',
					left: '10%',
					width: '80%',
					borderRadius: '6px',
					boxShadow: 24,
					p: 1
				}}
				component="form"
				onSubmit={handleSubmit}
			>
				{userContext &&
					<Grid container spacing={1}>

						<Grid item xs={12}>
							<Typography variant="h6">{t('rep_violation')}:</Typography>
						</Grid>

						<Grid item xs={12}>
							<TextField
								fullWidth
								multiple
								value={rep}
								inputProps={{
									maxLength: 200
								}}
								helperText={`${rep ? rep.length : 0}/${200}`}
								onChange={(e)=>{setRep(e.target.value)}}
							/>
						</Grid>

						<Grid item xs={6}>
							<Button fullWidth type="submit" key="send-report" size="medium" startIcon={<SendRoundedIcon />} variant="contained" color="info">
								{t('send')}
							</Button>
						</Grid>
						<Grid item xs={6} sx={{mb:1}}>
							<Button fullWidth type="reset" key="cancel-report" size="medium" variant="outlined" color="error" onClick={onClose}>
								{t('cancel')}
							</Button>
						</Grid>

					</Grid>
				}

			</Box>
		</Modal>
	)
}


export default function ProfileCard({profileContext, onClose}) {
	const [userInfo, setUserInfo] = useState(null)
	const [reloadUser, setReloadUser] = useState(true)
	const [repContext, setRepContext] = useState(null)
	const currUser = useSelector(state => state.auth.user)
	const authorized = useSelector(state=> state.auth.authorized)
	const dispatch = useDispatch()
	const {t} = useTranslation()

	useEffect(() => {
		if (profileContext && profileContext.user_id) {
			service.get("user/get", {
				params: {
					'user_id': profileContext.user_id
				}
			})
			.then((res) => {setUserInfo(res.data)})
			.catch((err) => {
				dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
			})
		}
	}, [reloadUser, profileContext])

	const sendFriendRequest = async (user_id) => {
		service.post('/user/send_friend_invite', {'subject_id': user_id})
		.then((res) => {setReloadUser(prev => !prev)})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const acceptFriendRequest = async (user_id) => {
		service.post('/user/accept_friend_invite', null, {'params': {'user_id': user_id}})
		.then((res) => {setReloadUser(prev => !prev)})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const declineFriendRequest = async (user_id) => {
		service.post('/user/remove_friend_invite', null, {'params': {'user_id': user_id}})
		.then((res) => {setReloadUser(prev => !prev)})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const removeFriend = async (user_id) => {
		service.post('/user/remove_friend', null, {'params': {'user_id': user_id}})
		.then((res) => {setReloadUser(prev => !prev)})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const followUser = async (user_id) => {
		service.post('/user/follow', {'subject_id': user_id})
		.then((res) => {setReloadUser(prev => !prev)})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const unfollowUser = async (user_id) => {
		service.post('/user/unfollow', {'subject_id': user_id})
		.then((res) => {setReloadUser(prev => !prev)})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const reportUser = async (user_id, repText) => {
		service.post('/user/black_list', {'subject_id': user_id, 'report_text': repText})
		.then((res) => {setReloadUser(prev => !prev)})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const unblockUser = async (user_id) => {
		service.post('/user/remove_from_black_list', null, {'params': {'user_id': user_id}})
		.then((res) => {setReloadUser(prev => !prev)})
		.catch((err) => {
			dispatch(notifyAction({'notification': 'network_error_notify', 'severity': 'error'}))
		})
	}

	const interactions = []
	if (authorized && userInfo && currUser && currUser.id != userInfo.id) {
		if (!userInfo.is_my_friend &&
			!userInfo.is_blocked_me &&
			!userInfo.is_blocked_by_me &&
			!userInfo.invited_me_as_friend &&
			!userInfo.invited_by_me_as_friend) {
			interactions.push(
				<Button sx={{height: '100%'}} key="add-friend-request" size="small" startIcon={<PersonAddRoundedIcon />} variant="contained" color="primary" onClick={async () => {
						await sendFriendRequest(userInfo.id)
				}}>
					{t('friend')}
				</Button>)
		}

		if (userInfo.is_my_friend) {
			interactions.push(
				<Button key="remove-friend" size="small" startIcon={<PersonRemoveRoundedIcon />} variant="contained" color="primary" onClick={async () => {
						await removeFriend(userInfo.id)
				}}>
					{t('remove_friend')}
				</Button>)
		}

		if (!userInfo.is_my_friend &&
			!userInfo.is_blocked_me &&
			!userInfo.is_blocked_by_me &&
			userInfo.invited_me_as_friend &&
			!userInfo.invited_by_me_as_friend) {
			interactions.push(
				<Button key="accept-friend-request" size="small" startIcon={<PersonAddRoundedIcon />} variant="contained" color="success" onClick={async () => {
						await acceptFriendRequest(userInfo.id)
				}}>
					{t('accept_friend')}
				</Button>)
			interactions.push(
				<Button key="decline-friend-request" size="small" startIcon={<DoNotDisturbRoundedIcon />} variant="contained" color="error" onClick={async () => {
						await declineFriendRequest(userInfo.id)
				}}>
					{t('decline_friend')}
				</Button>)
		}

		{/*
		if (!userInfo.is_blocked_me &&
			!userInfo.is_blocked_by_me &&
			!userInfo.is_followed_by_me) {
			interactions.push(
				<Button sx={{height: '100%'}} key="follow" size="small" startIcon={<GroupAddRoundedIcon />} variant="contained" color="info" onClick={async () => {
						await followUser(userInfo.id)
				}}>
					Follow
				</Button>)
		}

		if (!userInfo.is_blocked_me &&
			!userInfo.is_blocked_by_me &&
			userInfo.is_followed_by_me) {
			interactions.push(
				<Button key="unfollow" size="small" startIcon={<GroupRemoveRoundedIcon />} variant="contained" color="info" onClick={async () => {
						await unfollowUser(userInfo.id)
				}}>
					Unfollow
				</Button>)
		}
		*/}

		if (!userInfo.is_blocked_by_me) {
			interactions.push(
				<Button key="block" size="small" startIcon={<ReportRoundedIcon />} variant="outlined" color="error" onClick={() => {
						setRepContext(userInfo)
				}}>
					{t('rep_block')}
				</Button>)
		}

		if (userInfo.is_blocked_by_me) {
			interactions.push(
				<Button key="unblock" size="small" startIcon={<CloseRoundedIcon />} variant="contained" color="warning" onClick={async () => {
						await unblockUser(userInfo.id)
				}}>
					{t('unblock')}
				</Button>)
		}
	}

	const statuses = []
	let uage = null
	if (userInfo) {
		if (userInfo.invited_by_me_as_friend) {
			statuses.push(<Chip key="ibmaf" sx={{px:1}} size="small" icon={<ScheduleRoundedIcon />} label={t('friend_chip')}/>)
		}
		if (userInfo.is_my_friend) {
			statuses.push(<Chip key="imb" sx={{px:1}} size="small" icon={<PeopleRoundedIcon />} color="success" variant="outlined" label={t('friend_chip')}/>)
		}
		if (userInfo.is_followed_by_me) {
			statuses.push(<Chip key="ifbm" sx={{px:1}} size="small" icon={<PeopleRoundedIcon />} color="info" variant="outlined" label={t('sub_chip')}/>)
		}
		if (userInfo.is_blocked_by_me) {
			statuses.push(<Chip key="ibbm" sx={{px:1}} size="small" icon={<DoNotDisturbRoundedIcon />} color="error" variant="outlined" label={t('block_chip')}/>)
		}

		if (userInfo.birth_date) {
			try {
				const curr_dt = dayjs()
				const bd = dayjs(userInfo.birth_date, 'YYYY-MM-DD')
				const delta = Math.floor(curr_dt.diff(bd, 'year'))
				if (delta > 0 && delta < 112) uage = delta
			} catch (e) {

			}
		}
	}

	return (
		<Modal
			open={!!profileContext}
			onClose={onClose}
		>

			<Box
				sx={{
					maxWidth: 400,
					bgcolor: '#fafafa',
					position: 'absolute',
					top: '10%',
					left: '10%',
					width: '80%',
					borderRadius: '6px',
					boxShadow: 24,
					p: 2
				}}
			>
				<ReportModal
					onSubmit={reportUser}
					userContext={repContext}
					onClose={()=>{setRepContext(null)}}
				/>

				{!!userInfo &&
					<Grid
						container
						spacing={0.2}
						justifyContent="center"
					>
						<Grid item xs={2}>
							<Avatar alt="Ava" src="" />
						</Grid>
						<Grid container item xs={10}>
							<Grid item xs={12}>
								<Typography variant="h6">{userInfo.name}</Typography>
							</Grid>
							<Grid item xs>
								{!!userInfo.gender &&
									<Typography sx={{color: '#5d5d5d'}} variant="body2">{t(userInfo.gender)}</Typography>
								}
							</Grid>
							<Grid item xs>
								{!!uage &&
									<Typography sx={{color: '#5d5d5d'}} variant="body2">{uage} {t('age')}</Typography>
								}
							</Grid>
						</Grid>

						<Grid container item xs={12} sx={{my:1}}>
							{statuses.map(item => {
								return (
									<Grid key={item.key} item xs>
										{item}
									</Grid>
								)
							})}
						</Grid>

						{!!userInfo.bio &&
						<Grid item container xs={12} sx={{mx:2, my:1}}>
							<Grid item xs={12}>
								<Typography variant="h7">{t('about_user')}:</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									multiline
									readOnly
									value={userInfo.bio}
									rows={4}
									inputProps={{
										readOnly: true,
										disabled: true
									}}
								/>
							</Grid>
						</Grid>
						}

						{interactions.length > 0 &&
							<Grid container item xs={12} spacing={1} sx={{mt:1}}>
								{interactions.map(item => {
									return (
										<Grid key={item.key} item xs>
											{item}
										</Grid>
									)
								})}
							</Grid>
						}

					</Grid>
				}

			</Box>
		</Modal>
	)
}