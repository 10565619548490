import classes from './EventList.module.css'
import {forwardRef, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {fetchMyIntents} from 'store/thunks/events'
import {useTranslation} from 'react-i18next'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import FolderIcon from '@mui/icons-material/Folder'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListSubheader from '@mui/material/ListSubheader'
import {deepOrange} from '@mui/material/colors'
import WrongLocationRoundedIcon from '@mui/icons-material/WrongLocationRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'


const EventList = forwardRef((props, ref) => {
	const intents = useSelector(state => state.events.intent_list)
	const dispatch = useDispatch()
	const {t} = useTranslation()
	const {
		eventContextHandler,
		organizerSection,
		participateSection,
		invitesSection,
		...restProps} = props
	// TODO: refactor list to small context + on open event context -> fetch from api
	// const organizedEvents = []
	// const intentedEvents = []
	// const invitedEvents = []
	// for (let item of intents) {
	// 	if (item.intent_type == 'organizer') {
	// 		organizedEvents.push(item)
	// 	} else if (item.intent_type == 'participate') {
	// 		intentedEvents.push(item)
	// 	} else if (item.intent_type == 'invite') {
	// 		invitedEvents.push(item)
	// 	}
	// }

	const sections = []
	if (organizerSection) {
		sections.push({'section': t('organizer'), 'events': intents.organized})
	}
	if (participateSection) {
		sections.push({'section': t('participate'), 'events': intents.participating})
	}
	if (invitesSection) {
		sections.push({'section': t('invites'), 'events': intents.invites})
	}

	useEffect(() => {
		dispatch(fetchMyIntents())
	}, [])

	return (
		<Box className={classes['event-list-box']} {...restProps} ref={ref}
			sx={{
				maxWidth: 360,
				bgcolor: 'white',
				bottom: '200px',
				pb: 1
			}}
		>
			<List
				dense
				sx={{
					m: 0,
					p: 0,
					width: '100%',
					left: 0,
					height: '100%',
					overflow: 'auto'
				}}
			>
				
				{sections.map(({section, events}) => (
					<li key={section}>
						<ul>
							<ListSubheader>{section}</ListSubheader>
							{events.map((item) => (
								<ListItem key={item.event_id} sx={
									item.event_status === 'cancelled'
									? {
										color: 'red'
									}
									: (item.event_acknowledge_status ? {color: 'green'} : {})
								}>
									<ListItemButton onClick={()=>{eventContextHandler(item)}}>
										<ListItemAvatar>
											<Avatar
												sx={{
													bgcolor: (item.intent_type === 'participating' ? '#5bbe4b' : (item.intent_type === 'organizer' ? '#3ad4dd' : '#2d8fd0'))
												}}
											>
												{item.event_title.substring(0,1)}
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary={item.event_title} />
										{item.event_status === 'cancelled' &&
											<ListItemIcon sx={{color: 'red'}}>
												<WrongLocationRoundedIcon />
											</ListItemIcon>
										}
										{item.event_acknowledge_status &&
											<ListItemIcon sx={{color: 'green'}}>
												<CheckCircleRoundedIcon />
											</ListItemIcon>
										}
									</ListItemButton>
								</ListItem>
							))}
						</ul>
					</li>
				))}

			</List>
		</Box>
	)
})

export default EventList