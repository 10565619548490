import {createSlice, createAction} from "@reduxjs/toolkit"
import {fetchEventByID} from 'store/thunks/events'


const initialState = {
	state: 'root', // root/profile_menu/event_view/event_edit/event_edit_marker
	state_context: {}
}

export const setRootStateAction = createAction('set_root_state')
const setRootStateReducer = (state, action) => {
	state.state = 'root'
	state.state_context = {}
}

export const setProfileMenuStateAction = createAction('set_profile_menu_state')
const setProfileMenuStateReducer = (state, action) => {
	state.state = 'profile_menu'
	state.state_context = {}
}

export const setEventViewStateAction = createAction('set_event_view_state')
const setEventViewStateReducer = (state, action) => {
	state.state_context = action.payload
	state.state = 'event_view'
}

export const setEventEditStateAction = createAction('set_event_edit_state')
const setEventEditStateReducer = (state, action) => {
	state.state_context = action.payload
	state.state = 'event_edit'
}

export const setEventEditMarkerStateAction = createAction('set_event_edit_marker_state')
const setEventEditMarkerStateReducer = (state, action) => {
	state.state_context = action.payload
	state.state = 'event_edit_marker'
}

export const statesSlice = createSlice({
	name: 'states',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			setRootStateAction,
			setRootStateReducer
		)
		builder.addCase(
			setProfileMenuStateAction,
			setProfileMenuStateReducer
		)
		builder.addCase(
			setEventViewStateAction,
			setEventViewStateReducer
		)
		builder.addCase(
			setEventEditStateAction,
			setEventEditStateReducer
		)
		builder.addCase(
			setEventEditMarkerStateAction,
			setEventEditMarkerStateReducer
		)

		builder.addCase(
			fetchEventByID.rejected,
			setRootStateReducer
		)
	}
})

export default statesSlice.reducer