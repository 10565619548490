import {createSlice, createAction} from "@reduxjs/toolkit"
import {ackEvent, cancelEvent, fetchEventByID, publishEvent, listEvents, fetchMyIntents, createIntent} from "store/thunks/events"
import {mergeEvents, mergeIntents, draftFromEventContext} from 'utils/event_list_utils.js'
// import {notifyAction} from 'store/slice/notify'

const initialState = {
	event_draft: {
		title: '',
		event_type: '',
		visible_to: 'all',
		tags: [],
		event_date: '',
		event_time: '',
		desc: '',
		group: null,
		lng: '',
		lat: '',
		related_event: null
	},

	event_list: [],
	reload_event_list: false,
	intent_list: [],
	curr_event_context: null,

	setting_marker: false,
	setting_event_data: false,
	pending_publish: false
}

export const initEventDraftAction = createAction('init_event_draft')
const initEventDraftReducer = (state, action) => {
	state.event_draft = {
		title: '',
		event_type: '',
		visible_to: 'all',
		tags: [],
		event_date: '',
		event_time: '',
		desc: '',
		group: null,
		lng: '',
		lat: '',
		related_event: null
	}
}

export const setMyIntentOnEventContextAction = createAction('set_my_intent_ec')
const setMyIntentOnEventContextReducer = (state, action) => {
	if (state.curr_event_context) {
		state.curr_event_context.participating = true
	}
}

export const removeMyIntentOnEventContextAction = createAction('remove_my_intent_ec')
const removeMyIntentOnEventContextReducer = (state, action) => {
	if (state.curr_event_context) {
		state.curr_event_context.participating = false
	}
}

export const dropEventDraftAction = createAction('drop_event_draft')
const dropEventDraftReducer = (state, action) => {
	state.event_draft = null
}

export const dropEditEventDraftAction = createAction('drop_edit_event_draft')
const dropEditEventDraftReducer = (state, action) => {
	if (state.event_draft && state.event_draft.related_event)
		state.event_draft = null
}

export const editEventAction = createAction('edit_event')
const editEventReducer = (state, action) => {
	state.event_draft = draftFromEventContext(action.payload)
	state.curr_event_context = null
	state.setting_event_data = true
	state.setting_marker = false
}

export const setCurrentEventContextAction = createAction('set_current_event_context')
const setCurrentEventContextReducer = (state, action) => {
	for (let event of state.event_list)
	{
		if (event.id == action.payload) {
			state.curr_event_context = event
			break
		}
	}
}

export const removeCurrentEventContextAction = createAction('remove_current_event_context')
const removeCurrentEventContextReducer = (state, action) => {
	state.curr_event_context = null
}

export const closeEventContextAction = createAction('close_event_context')
const closeEventContextReducer = (state, action) => {
	state.setting_marker = false
	state.setting_event_data = false
	state.curr_event_context = null
}

export const settingEventDataAction = createAction('set_event_data')
const settingEventDataReducer = (state, action) => {
	if (action.payload) {
		state.event_draft = {
			...state.event_draft,
			...action.payload
		}
	}
	state.setting_marker = false
	state.setting_event_data = true
	state.curr_event_context = null
}

export const settingEventMarkerAction = createAction('set_event_marker')
const settingEventMarkerReducer = (state, action) => {
	state.setting_marker = true
	state.setting_event_data = false
	state.curr_event_context = null
}

export const setLngLatAction = createAction('set_lng_lat')
const setLngLatReducer = (state, action) => {
	state.event_draft.lng = action.payload.lng
	state.event_draft.lat = action.payload.lat
}

export const updateEventDraftAction = createAction('update_edraft')
const updateEventDraftReducer = (state, action) => {
	state.event_draft = {
		...state.event_draft,
		...action.payload
	}
}

export const publishEventAction = createAction('publish_event')
const publishEventReducer = (state, action) => {
	mergeEvents([action.payload], state.event_list)
	state.pending_publish = false
}

export const reloadEventListAction = createAction('reload_event_list')
const reloadEventListReducer = (state, action) => {
	if (action.payload && action.payload.remove_event) {
		for (let i = 0; i < state.event_list.length; ++i) {
			if (state.event_list[i].id == action.payload.remove_event) {
				state.event_list.splice(i, 1)
				break
			}
		}
	}
	state.reload_event_list = true
}

export const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			reloadEventListAction,
			reloadEventListReducer
		)
		builder.addCase(
			setMyIntentOnEventContextAction,
			setMyIntentOnEventContextReducer
		)
		builder.addCase(
			removeMyIntentOnEventContextAction,
			removeMyIntentOnEventContextReducer
		)
		builder.addCase(
			initEventDraftAction,
			initEventDraftReducer
		)
		builder.addCase(
			dropEditEventDraftAction,
			dropEditEventDraftReducer
		)
		builder.addCase(
			dropEventDraftAction,
			dropEventDraftReducer
		)
		builder.addCase(
			editEventAction,
			editEventReducer
		)
		builder.addCase(
			setCurrentEventContextAction,
			setCurrentEventContextReducer
		)
		builder.addCase(
			removeCurrentEventContextAction,
			removeCurrentEventContextReducer
		)
		builder.addCase(
			settingEventDataAction,
			settingEventDataReducer
		)
		builder.addCase(
			settingEventMarkerAction,
			settingEventMarkerReducer
		)
		builder.addCase(
			setLngLatAction,
			setLngLatReducer
		)
		builder.addCase(
			closeEventContextAction,
			closeEventContextReducer
		)
		builder.addCase(
			updateEventDraftAction,
			updateEventDraftReducer
		)


		builder.addCase(
			publishEventAction,
			publishEventReducer
		)
		// builder.addCase(
		// 	publishEvent.pending,
		// 	(state, action) => {
		// 		state.pending_publish = true
		// 	}
		// )
		// builder.addCase(
		// 	publishEvent.fulfilled,
		// 	(state, action) => {
		// 		mergeEvents([action.payload], state.event_list)
		// 		state.pending_publish = false
		// 	}
		// )
		// builder.addCase(
		// 	publishEvent.rejected,
		// 	(state, action) => {
		// 		console.log('rejected publish event')
		// 		state.pending_publish = false
		// 		// TODO: process reject
		// 	}
		// )


		builder.addCase(
			listEvents.pending,
			(state, action) => {
				// TODO: process pending list events
			}
		)
		builder.addCase(
			listEvents.fulfilled,
			(state, action) => {
				state.reload_event_list = false
				mergeEvents(action.payload.event_list, state.event_list, action.payload.geofilter)
			}
		)


		builder.addCase(
			fetchMyIntents.pending,
			(state, action) => {
			}
		)
		builder.addCase(
			fetchMyIntents.fulfilled,
			(state, action) => {
				state.intent_list = action.payload
			}
		)


		builder.addCase(
			createIntent.pending,
			(state, action) => {
				// TODO: process pending
			}
		)
		builder.addCase(
			createIntent.fulfilled,
			(state, action) => {
				if (state.intent_list && state.intent_list.participating) mergeIntents([action.payload], state.intent_list.participating)
				else state.intent_list.participating = [action.payload]
			}
		)


		builder.addCase(
			fetchEventByID.pending,
			(state, action) => {
				// TODO: process pending
			}
		)
		builder.addCase(
			fetchEventByID.fulfilled,
			(state, action) => {
				state.curr_event_context = action.payload
			}
		)


		builder.addCase(
			cancelEvent.pending,
			(state, action) => {
				// TODO: process pending
			}
		)
		builder.addCase(
			cancelEvent.fulfilled,
			(state, action) => {
				state.curr_event_context = action.payload
			}
		)


		builder.addCase(
			ackEvent.pending,
			(state, action) => {
				// TODO: process pending
			}
		)
		builder.addCase(
			ackEvent.fulfilled,
			(state, action) => {
				state.curr_event_context = action.payload
			}
		)
	}
})

export default eventsSlice.reducer