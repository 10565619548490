import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setRootStateAction, setEventViewStateAction} from 'store/slice/states'
import {dropEditEventDraftAction, removeCurrentEventContextAction} from 'store/slice/events'

import Skeleton from '@mui/material/Skeleton'

import SliderMenu from 'components/SliderMenu/SliderMenu'
import EventContext from 'components/EventContext/EventContext'
import EventEditContext from 'components/EventEditContext/EventEditContext'

export default function EventSlider() {
	const [open, setOpen] = useState(false)
	const eventData = useSelector(state => state.states.state_context)
	const currState = useSelector(state => state.states.state)

	const dispatch = useDispatch()

	const handleShowEventLocation = (event_id) => {
		dispatch(setEventViewStateAction({'id': event_id, 'fly_to_location': true}))
	}

	useEffect(() => {
		if (currState === 'event_view' || currState === 'event_edit')
			setOpen(true)
		else {
			setOpen(false)
		}
	}, [currState])

	const states = {
		'event_view': <EventContext onClose={()=>{dispatch(setRootStateAction())}} eventData={eventData} onShowEventLocation={handleShowEventLocation} />,
		'event_edit': <EventEditContext eventID={eventData ? eventData.id : null} />,
		'default': <Skeleton variant="rounded" width="100%" height="300px" />
	}

	return (
		<div>
			<SliderMenu
				white={currState !== 'event_edit'}
				open={open}
				onClose={()=>{
					setOpen(false)
					if (currState !== 'event_edit_marker') {
						dispatch(removeCurrentEventContextAction())
						dispatch(dropEditEventDraftAction())
						dispatch(setRootStateAction())
					}
				}}
				onOpen={()=>{setOpen(true)}}
			>
				{states[currState] !== undefined ? states[currState] : states['default']}
			</SliderMenu>
		</div>
	)
}