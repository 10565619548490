import {useTranslation} from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'

import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'


export default function SupportModal() {
	const {t} = useTranslation()

	return (
		<Box
			sx={{
				position: 'absolute',
				left: '10%',
				top: '20%',
				maxWidth: '400px',
				width: '80%',
				bgcolor: '#f7f7f7',
				borderRadius: 3,
				p: 2
			}}
		>
			<Grid
				container
				rowSpacing={0}
				columnSpacing={0}
				sx={{
					m: 0,
					p: 0
				}}
			>
				<Grid
					item
					container
					xs={12}
					sx={{
						bgcolor: 'white',
						// mx: 1,
						// width: '80%',
						p: 2,
						// m: 0,
						borderRadius: 2
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							mb:1,
							borderBottom: '1px solid #878787'
						}}
					>
						<Typography
							variant="h6"
							align="center"
						>
							{t('service_rules')}:
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="body2">
							{t('service_rules_text0')}
						</Typography>
					</Grid>
					
					<Grid item xs={12}>
						<Typography variant="body2">
							{t('service_rules_text1')}
						</Typography>
					</Grid>
					
					<Grid item xs={12}>
						<Typography variant="body2">
							{t('service_rules_text2')}
						</Typography>
					</Grid>
				</Grid>
				
				<Grid item xs={12} sx={{mt:5}}>
					<Typography variant="body2">
						{t('contacts')}:
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<List dense>
						<ListItem>
							<ListItemText
								sx={{m:0, p:0}}
								primary={t('cooperation') + ' / ' + t('complaints') + ':'}
								secondary='igo-help@yandex.ru'
							/>
						</ListItem>
					</List>
				</Grid>

			</Grid>
		</Box>
	)
}