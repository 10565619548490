import classes from './MainMenu.module.css'
import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setRootStateAction, setProfileMenuStateAction} from 'store/slice/states'

import MainMenuButton from './MainMenuButton/MainMenuButton'
import SliderMenu from 'components/SliderMenu/SliderMenu'

import LoginScreen from './Screens/LoginScreen/LoginScreen'
import RegisterScreen from './Screens/RegisterScreen/RegisterScreen'
import RestorePasswordScreen from './Screens/RestorePasswordScreen/RestorePasswordScreen'
import ProfileScreen from './Screens/ProfileScreen/ProfileScreen'
import ProfileSettingsScreen from './Screens/ProfileSettingsScreen/ProfileSettingsScreen'


export default function MainMenu() {
	const [open, setOpen] = useState(false)
	const authorized = useSelector(state => state.auth.authorized)
	const currState = useSelector(state => state.states.state)
	const [state, setState] = useState(() => {
		return authorized ?
			'Profile' :
			'Login'
	})

	const dispatch = useDispatch()

	useEffect(() => {
		if (authorized) {
			setState('Profile')
		} else {
			setState('Login')
		}
	}, [authorized])

	useEffect(() => {
		if (currState === 'profile_menu')
			setOpen(true)
		else
			setOpen(false)
	}, [currState])

	const states = {
		'Login': (<LoginScreen stateHandler={setState} />),
		'Register': (<RegisterScreen stateHandler={setState} />),
		'RestorePassword': (<RestorePasswordScreen stateHandler={setState} />),
		'Profile': (<ProfileScreen stateHandler={setState} open={open} />),
		'ProfileSettings': (<ProfileSettingsScreen stateHandler={setState} />)
	}

	return (
		<div>
			<MainMenuButton onClick={() => {dispatch(setProfileMenuStateAction())}} />

			<SliderMenu
				open={open}
				onClose={()=>{setOpen(false);dispatch(setRootStateAction())}}
				onOpen={()=>{setOpen(true)}}
			>
				{states[state]}
			</SliderMenu>
		</div>
	)
}