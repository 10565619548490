import service from "utils/axios"
import {createAsyncThunk} from "@reduxjs/toolkit"


export const publishEvent = createAsyncThunk(
	'publish_event',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('event/publish', data)
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const cancelEvent = createAsyncThunk(
	'cancel_event',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('event/cancel', null, {
				'params': data
			})
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const ackEvent = createAsyncThunk(
	'ack_event',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('event/acknowledge', null, {
				'params': data
			})
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const listEvents = createAsyncThunk(
	'list_events',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('event/list', data)
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const createIntent = createAsyncThunk(
	'create_intent',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('event/intent/create', null, {
				'params': data
			})
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const removeIntent = createAsyncThunk(
	'remove_intent',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.post('event/intent/remove', null, {
				'params': data
			})
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const fetchMyIntents = createAsyncThunk(
	'fetch_my_intents',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.get('event/intent/my/list')
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)

export const fetchEventByID = createAsyncThunk(
	'fetch_event_by_id',
	async (data, {rejectWithValue}) => {
		try {
			const reply = await service.get('event/get',{
				params: {
					'event_id': data
				}
			})
			return reply.data
		} catch(e) {
			if (e.response && e.response.status) {
				return rejectWithValue(e.response.status)
			} else if (e.response && e.response.data.message) {
				return rejectWithValue(e.response.data.message)
			} else {
				return rejectWithValue(e.message)
			}
		}
	}
)