import classes from './AcceptMarkerPosition.module.css'
import Fab from '@mui/material/Fab'
import DoneIcon from '@mui/icons-material/Done'
import {useTranslation} from 'react-i18next'

import {useSelector, useDispatch} from 'react-redux'
import {settingEventDataAction} from 'store/slice/events'
import {setEventEditStateAction} from 'store/slice/states'


export default function AcceptMarkerPosition() {
	const settingMarker = useSelector(state => state.states.state === 'event_edit_marker')
	const dispatch = useDispatch()
	const {t} = useTranslation()

	if (settingMarker)
		return (
			<div className={classes.button}>
				<Fab
					size="medium"
					variant="extended"
					onClick={() => {
						dispatch(setEventEditStateAction())
					}}
					sx={{
						borderRadius: 4,
						bgcolor: '#74c333',//'#74c333', '#81d340'
						color: 'white'
					}}

				>
					<DoneIcon />
					{t('accept_position')}
				</Fab>
			</div>
		)
	else return <></>
}